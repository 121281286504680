import React from 'react'
import { Row,Col,Container} from 'react-bootstrap'
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Navbar from '../../navbar/header'
import '../vortex/vortex.css'
import Fade from 'react-reveal/Fade';
import Footer from '../../footer/footer'
import Products from '../../products/products'
import v1 from '../../../assets/vortex/v (1).jpg'
import v2 from '../../../assets/vortex/v (2).jpg'
import v3 from '../../../assets/vortex/v (3).jpg'
import v4 from '../../../assets/vortex/v (4).jpg'
import v5 from '../../../assets/vortex/v (5).jpg'
import v6 from '../../../assets/vortex/v (6).jpg'
import v7 from '../../../assets/vortex/v (7).jpg'
import vortexpdf from  '../../../assets/brochers/vortex.pdf'





const CarouselPage = () => {
    return (
      <>
         <Navbar />
         
    <Row>
      <Col sm={6}>
      <MDBCarousel
      activeItem={1}
      length={7}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={v1}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={v2}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={v3}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>

          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={v4}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>

          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={v5}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>

          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="6">
          <MDBView>
            <img
              className="d-block w-100"
              src={v6}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>

          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="7">
          <MDBView>
            <img
              className="d-block w-100"
              src={v7}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>

          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
      <button onClick={()=>window.open(vortexpdf)}  class="btnn" style={{width:'70%',marginRight:'3rem',marginTop:'2rem',marginLeft:'7rem'}}><i class="fa fa-download"></i>DOWNLOAD TECHNICAL DATASHEET</button>
      </Col>
      <Col  sm={6}>
        
      <h2 className='headername' style={{color:'red',marginTop:'3rem',marginLeft:'5rem'}}>VORTEX FLOW METER</h2>
      <Fade bottom>
      <p style={{marginLeft:'5rem',marginBottom:'2rem',marginRight:'5rem'}} >
      The measuring principle is based on the Karman vortex street. The measuring tube contains
a bluff body at which vortex shedding occurs and which is detected by a sensor unit located
behind. The frequency f of the vortex shedding is proportional to the flow velocity v. The nondimensional Southall number S describes the relationship between vortex frequency f, width
d of the bluff body and the average flow velocity v:<br></br>
f=SV/d<br></br>
The vortex frequency is recorded at the flow sensor and evaluated at the signal converter.<br></br>
Vortex flowmeters are used to measure the flow of gases, vapours and liquids at completely<br></br>
1.Measurement of saturated steam and superheated steam<br></br>
2.Steam boiler monitoring<br></br>
3.Heat metering of steam and hot water<br></br>
4.Wide range of applications, gas, liquid, steam flow can be measured<br></br>
5.Measurement of consumption in compressed air systems<br></br>
6.Industrial Gases i.e. Air, Oxygen, Nitrogen, Coal Gas, Natural gas. etc.<br></br>
7.Industrial Liquids i.e. Water, Oil, Food liquid, Chemicals etc. <br></br>
      </p>
      </Fade>
      </Col>
      </Row>
      
      
      
   <Fade bottom>
      
      
  <Container>
  <h1 style={{color:'red'}}>SPECIFICATION</h1>
  <table  style={{fontSize:'13px'}} >
  <tr>
    <th>Medium
</th>
    <th>Steam, Compressed Air, Oxygen, Nitrogen,Natual Gas, Gasoline,
Light Oils, Heavy Oils, Food Oils, Solvents, Lubrication Oils,
Chemicals, Pharma Chemicals, Clear Hot Water etc.
</th>
  </tr>
  <tr>
    <td style={{width:'20%'}}>Size</td>
    <td>DN25~DN300mm
</td>
  </tr>
  <tr>
    <td>Accuracy</td>
    <td>±0.5%
</td>
  </tr>
  <tr>
    <td>Repeatability</td>
    <td>±0.1% of Span
</td>
  </tr>
  <tr>
    <td>Medium temperature</td>
    <td>-40’C to 350’C
</td>
    
  </tr>
  <tr>
    <td>Medium pressure  </td>
    <td>up to 60 Bar

</td>

  </tr>
  <tr>
    <td>Body Material</td>
    <td>SS304,SS316</td>
  </tr>
  
  <tr>
    <td>Compensation</td>
    <td>Pressure & Temperature Compensation</td>
  </tr>
  <tr>
    <td>Enclosure Material </td>
    <td>Aluminium</td>
  </tr>
  <tr>
    <td>Power supply</td>
    <td>24VDC,lithium battery</td>
  </tr>
  <tr>
    <td>Signal output</td>
    <td>4～20mA, Pulse-NPN and Relay </td>
    
  </tr>
  <tr>
    <td>Communication Protocol </td>
    <td>RS485,GSM/GPRS,HART,LORA/NB-IOT</td>

  </tr>
  <tr>
    <td>Display</td>
    <td>LCD Display, Flow rate, Totalizer, Pressure Temperature</td>
</tr>
  <tr>
    <td>Explosion-proof</td>
    <td>Flame Proof (FLP)
</td>
  </tr>
  <tr>
    <td>Protection proof</td>
    <td>IP65,IP67& IP68</td>
  </tr>
  <tr>
    <td>Flow Transmitter</td>
    <td>Integral, Remote
</td>
  </tr>
  <tr>
    <td>Work environment</td>
    <td>Ambient temperature：-20～+60’C，Ambient humidity：5%～85%

</td>
  </tr>
  <tr>
    <td>End Connection</td>
    <td>Flange-end and Wafer Connection
</td>
  </tr>
 
 
</table>
  </Container>
   
         
  </Fade>
  <Products/>
          <Footer/>
      
  
      
       
      
  </>
      
    );
    
  }
  
  export default CarouselPage;