import React  from 'react';
import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import '../scada/scada.css'
import c2 from '../../../assets/03.jpg'
import { Container, Row,Col } from 'react-bootstrap';
import scadaimage from '../../../assets/scadaimage.png'

import Zoom from 'react-reveal/Zoom';
import HMI1 from '../../../assets/HMI1.png'
import PLCS from '../../../assets/PLCS.jpg'
import VFDAC10 from '../../../assets/VFDAC10.jpg'






function scada() {
    return (
        <>
        <Navbar/>
        
       <Zoom buttom> <img  style={{width:'1520px',height:'500px'}}src={c2} alt=''/></Zoom>
        
  
<div className='heading' >
  <h2  style={{marginTop:'3rem',color:'red'}}>KingSCADA has 20 years of development history</h2>
<h3 style={{color:'red'}}>Overview</h3>
<p>KingSCADA is a Windows-based high-end SCADA software used by medium and large companies. KinSCADA is featured with complete<br />functional support, integrated management, modular development, high stability, dynamic graphics, reusable graphics library, intelligent <br />diagnosis, data block acquisition, flexible network architecture, perfect alarm system, powerful historical services &amp; display function, web server/web client,<br />mobile client, WeChat client, more than 5000+ protocol support and many more.It can combine multiple production and information <br />management systems together to achieve optimal productivity. It&rsquo;s a platform to handle all applications in all <br />verticals of automation industry.</p>
</div>
<Container style={{marginTop:'3rem'}}>
<Row class='heading'>
    <Col md='6'>
<ul style={{}}>
<li>Integrates process controls with on-site operation and field management systems.</li>
<li>Designed for large-scale systems with high performance</li>
<li>Versatile enough to be applied to a wide variety of industries</li>
<li>A reliable, secured monitoring and control system</li>
</ul>
</Col>
<Col md='6'>
<ul>
<li>Higher efficiency, brings you quicker results at a lower cost</li>
<li>Guarantee your investment with a stable structure and the flexibility to grow exponentially</li>
<li>Valid data is the key aspect of the enterprise information system</li>
</ul>
</Col>
</Row>
</Container>
<Container className='heading' style={{marginTop:'3rem',color:'red'}}>
<h3  style={{color:'red'}}>KingSCADA Features</h3>
<p>KingSCADA &ndash; High efficiency, powerful functions, and competitive price. Choose KingSCADA for your monitoring and control system.</p>
</Container>
<Container  style={{marginTop:'2rem'}}>
<Row>
    <Col sm>
    <div class="card">
  <div class="container">
  <h5><b>Alarm &amp; Event</b></h5>
<p>Alarming feature is an important part in any automation project. KingSCADA provides powerful alarm and event function.</p>
  </div>
</div>
    </Col>
    <Col sm><div class="card">
  <div class="container">
  <h5>Alarm &amp; Event</h5>
<p>Alarming feature is an important part in any automation project. KingSCADA provides powerful alarm and event function.</p>
  </div>
</div></Col>
    <Col sm><div class="card">
  <div class="container">
  <h5>Trend curve &amp; charts</h5>
<p>KingSCADA has trend curve, pie chart, column chart, XY chart, &amp; packaged OCX trend to display real-time &amp; historical data.</p>
  </div>
</div></Col>
  </Row>
  <Row style={{marginTop:'2rem'}}>
    <Col sm>
    <div class="card">
  <div class="container">
  <h5>Script &amp; Schedule</h5>
<p>KingSCADA provides Script trigger function and Schedule function, user will finish the Schedule via triggering the script.</p>
  </div>
</div>
    </Col>
    <Col sm><div class="card">
  <div class="container">
  <h5>Multiple Redundancy</h5>
<p>KingSCADA provides multiple redundancy features that include dual SCADA station redundancy.</p>
  </div>
</div></Col>
    <Col sm><div class="card">
  <div class="container">
  <h5>Web Publishing</h5>
<p>KingSCADA provides an IIS-based Web Publishing function that integrates Web functionality with KingSCADA project.</p>
  </div>
</div></Col>
  </Row>
</Container>
<Container>
<Row>
    <Col md='6' >
    <h3 style={{marginTop:'4rem',color:'red'}}>KingSCADA Structure</h3>
<p>KingSCADA uses the most advance d development of graphic <br />technology, object-oriented development model, new concept of data <br />collection and control technology, and provides guarantee for data <br />reliability, accuracy and efficiency in enterprise production. KingSCADA uses <br />C/S architecture, support distributed servers: real-time data server,historical <br />data server, alarm/event server, IO collection server. It mainly includes IO <br />Server (Datacollector), development platform, <br />runtime platform (Project running)</p>
    </Col>
    <Col md='6'>
<img style={{marginTop:'2rem'}} src={scadaimage} alt=''/>
    </Col>
    </Row>
    <h1 className='heading' style={{marginTop:'2rem',color:'red'}}>Functions</h1>
<p   className='heading'>  KingSCADA &ndash; High efficiency, powerful functions, and competitive price. Choose KingSCADA for your monitoring and control system.</p>
    <Row  className='rowcolor' style={{marginTop:'2rem',color:'black'}}>
    
        <Col md='6'>
    <ul>
    <li>Integrated development environment, project management model based on solutions</li>
<li>Expandable/Rich graphic library, easy to learn and develop</li>
<li>Gorgeous dynamic graphic effects</li>
<li>Reusable data models and graphic model, improve configurationefficiency</li>
<li>Unlimited and clear zooming feature</li>
<li>Edit the project while in Runtime mode, no need to stop the project.</li>
<li>Powerful scripting language and error correction function while editing a script</li>
<li>User security management system based on demand</li>
</ul>
</Col>
<Col md='6'>

<ul>
<li>Concise recipe management feature, easy to</li>
<li>Implement recipe function</li>
<li>Powerful reporting feature, and analysis data efficiently</li>
<li>Support relational database perfectly, i.e. SQL, Oracle, etc.</li>
<li>Data Acquisition platform with good opening feature</li>
<li>Real-time Graphic and process display on web by Web publishing</li>
<li>Support both Project Server and Client with in SCADA</li>
<li>Support C/S architecture</li>
<li>KingSCADA can act as OPC Server and OPC Client</li>
<li>Multiple trending curves options, easy to compare</li>
</ul>
</Col>


</Row>

    </Container>
    
   
<h1 style={{textAlign:'center',color:'red',marginTop:'2rem'}}>RELATED PRODUCTS</h1>
    <Container>
<Row style={{marginTop:'2rem'}}>
      <Col span={6}>
      <div class="card">
  <a  href='#/hmi'><img src={HMI1} alt="Avatar" style={{width:'100%'}}/></a>
  <div class="container">
    <h4 style={{color:'red'}}><b>HMI</b></h4>
    
  </div>
</div>


      </Col>
      <Col span={6}>

      <div class="card">
  <a href='#/plc'><img src={PLCS} alt="Avatar" style={{width:'100%'}}/></a>
  <div class="container">
    <h4 style={{color:'red'}}><b>PLC</b></h4>
  </div>
</div>
      </Col>
      <Col span={6}>
      <div class="card">
  <a href='#/vfd'><img src={VFDAC10} alt="Avatar" style={{width:'100%'}}/></a>
  <div class="container">
    <h4 style={{color:'red'}}><b>VFD</b></h4>
  </div>
</div>

      </Col>
     


    </Row>
</Container>

        <Footer/>
        </>
    )
}

export default scada
