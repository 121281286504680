import React from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import i1 from '../../assets/water treatment.jpg'
import i2 from '../../assets/pharma.jpg'
import i3 from '../../assets/infra.jpg'
import i4 from '../../assets/etp stp.jpg'
import i5 from '../../assets/cement.jpg'
import i6 from '../../assets/power plant.jpg'
import './application.css'
import Flash from 'react-reveal/Flash';
import Fade from 'react-reveal/Fade';

function applications() {
    return (
      <div class="apply"style={{backgroundColor:'#FFFFFF'}}>
        
        <Container style={{textAlign:'center'}}>
       <Fade right> <h1 style={{color:'red',marginTop:'1rem'}}>APPLICATIONS</h1></Fade>
       <Flash>
        <div className="imgdiv-1">
        
        <Row>
        <Col sm={4} >
          <img className="application-img" src={i1} alt="apps" />
          <h4>WATER TREATMENT</h4>
        </Col>
        <Col sm={4} >
        <img className="application-img" src={i2} alt="apps" />
        <h4>PHARMA</h4>
        </Col>
        <Col sm={4} >
        <img className="application-img" src={i3} alt="apps" />
        <h4>SMART CITY PROJECTS</h4>
        </Col>
     

        </Row>
        </div>
        <div className="imgdiv-2" >
        <Row>
        <Col sm={4} >
        <img className="application-img" src={i4} alt="apps" />
        <h4>ETP STP</h4>
        </Col>
        <Col sm={4} >
        <img className="application-img" src={i5} alt="apps" />
        <h4>CEMENT</h4>
        </Col>
        <Col sm={4} >
        <img className="application-img" src={i6} alt="apps" />
        <h4>POWER</h4>
        </Col>
        </Row>
        </div>
        </Flash>
        </Container>
        
        </div>
    )
}

export default applications
