import React from 'react'
import Footer from '../footer/footer'
import Navbar from '../../components/navbar/header'
import S1 from '../../assets/S1.bmp'
import '../services/services.css'
import {Container} from 'react-bootstrap';

function services() {
    return (
        <>
        <Navbar/>
        <div>
            <h1 style={{color:'red',marginTop:'2rem'}}>CALIBRATION SERVICES</h1>
            <img style={{display: 'block',marginLeft: 'auto',marginRight: 'auto',width: '50%',marginTop:'2rem'}} src={S1} alt=''/>
            <Container>
            <p style={{marginTop:'2rem'}}>We will provide a Flowmeter Calibration Services. We have the Facility at our works. The facility uses the primary method of measuring the water flow by gravimetric principle or comparison method. The facility is designed for calibration of Flowmeters under controlled and stable flow conditions. Our calibration laboratory is ISO/IEC 4064-1:2014 accredited and has a capacity to calibrate Flow Meters up to 300 mm. Mirrant is one of the first companies to set up its own calibration laboratory in Hyderabad with NABL accreditation.</p>
    </Container>    
            </div>
        <Footer/>
        </>
    )
}
export default services
