import React  from 'react';
import Navbar from '../navbar/header'
import Footer from '../footer/footer'
import {Link} from 'react-router-dom'
import plc3b3 from '../../assets/plc3b3.jpg'
import specc3 from '../../assets/specc3.jpg'
import { Tab,Tabs } from 'react-bootstrap';
import { Row,Col, Container } from 'react-bootstrap';
import plcc3catalog from '../../assets/brochers/plcc3catalog.pdf'
import plcc3manual from '../../assets/brochers/plcc3manual.pdf'
function plcc3() {
    return (
        <
        >
            <Navbar/>
            <Row>
            <Col md={6}>
        <img src={plc3b3}/>
            </Col>
                <Col md={6}>
                    <Container>
        <h1 style={{fontSize:'45px',paddingTop:'30px',paddingRight:'100px',color:'red'}}>IVC3 Series Programmable Logic Controller</h1>
        <p style={{fontSize:'30px',paddingRight:'60px'}}>IVC3 series PLCs are the third generation of high-performance micro-PLCs, which integrated with the latest information technology, motion control technology and fieldbus control technology. This series of products have all passed CE and RoHS certification. Its excellent motion control function and flexible communication networking mode make it eh best option for product upgrade.</p>
        <Row>
              <Col md={6}>
              <button   onClick={()=>window.open(plcc3catalog)}   type="button" class="btn btn-danger">Catalog</button>
               < button   onClick={()=>window.open(plcc3manual)} style={{float:'right'}} type="button" class="btn btn-danger">Manual</button>
              </Col>
              </Row>
        </Container >
        </Col>
        </Row>
        <Container style={{paddingTop:'40px'}}  class="w3-container w3-teal" >
          <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
          <Tab eventKey="home" title="Features">
            <div>
        
            <p>1.Simplified software: User-friendly graphic interface, simplifying your application<br/>2.High performance and large capacity, well meeting the increasingly complicated process requirements<br/>3.Applicable to small-sized automation equipment (The input and output points are within 512)<br/>4.Dual-CPU design with high-speed and control algorithms separately processed<br/>5.Configured with an embedded operating system, executing multiple tasks in parallel<br/>6.Providing the motion control function; supporting two-axis linear and arc interpolation<br/>7.Standard Ethernet interfaces, CANopen protocol and two RS485 interfaces<br/>8.Support USB communication, which facilitates commissioning<br/>9.Time for executing a basic logical instruction is less than 0.065 μs<br/>10.8 high-speed inputs of 200 kHz or 4 AB phase inputs, supporting quadruple frequency control function.<br/>11.8 high-speed outputs of 200 kHz, supporting positive and negative pulses<br/>12.Abundant extension modules</p>             
       
                                  </div> 
            <div/>
          </Tab>
          <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
        <img src={specc3}/>
        
            <div />
          </Tab>
        </Tabs>
        </Container>

  
            <Footer/>
            
        </>
    )
}
export default plcc3