import React  from 'react';
import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import '../hmi/hmi.css'
import { Row,Col, Container } from 'react-bootstrap';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom'
import hmir from '../../../assets/hmir.png'
import hmir1 from '../../../assets/hmir1.png'
import hmir2 from '../../../assets/hmir2.png'
import invthmivs from '../../../assets/invthmivs.jpg' 
import invthmivt from '../../../assets/invthmivt.jpg' 
import invthmivk from '../../../assets/invthmivk.jpg' 
import hmiD from '../../../assets/HMID.JPG'
import hmiOD from '../../../assets/HMIOD.jpg'
import PLCS from '../../../assets/PLCS.jpg'
import VFDAC10 from '../../../assets/VFDAC10.jpg'
import SCADAS from '../../../assets/SCADAS.jpg'
import { color } from '@mui/system';
import iots from '../../../assets/iots.jpg'
import plcc1s1 from '../../../assets/plcc1s1.jpg'
import vfdgd20 from  '../../../assets/vfd/gd20-1.jpg'
function hmi() {
    return (
        <
        >
            <Navbar/>
   <Container style={{paddingTop:'30px'}}>
<Row>
    <Col md='4'>
    <div class="card">
  <img
    src={invthmivs}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">VS Series HMI</h5>
    <Link to="/hmivs" class="btn btn-danger">Click Here</Link>
  </div>
</div>
</Col>
        <Col md='4'>
    <div class="card">
  <img
    src={invthmivt}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">VT Series HMI</h5>
   
    <Link to="/hmivt" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col>
        <Col md='4'>
    <div class="card">
  <img
    src={invthmivk}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">
VK Series HMI</h5>
    <Link to="/hmivk" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col >
        
    </Row>
</Container>
   <Container>
   <h1 style={{paddingTop:'30px', color:'red',textAlign:'center' } }>RELATED PRODUCTS</h1>

<Row style={{marginTop:'2rem'}}>
      <Col span={6}>
      <div class="card">
  <Link to="/vfd"><img src={vfdgd20} alt="Avatar" style={{width:'100%'}}/></Link>
  <div class="container">
    <h4 style={{color:'red'}}><b>VFD</b></h4>
  </div>
</div>
      </Col>
      <Col span={6}>
      <div class="card" >
  <Link to="plc"><img src={plcc1s1} alt="Avatar" style={{width:'100%'}}/></Link>
  <div class="container">
    <h4 style={{color:'red'}}> <b>PLC</b></h4>
  </div>
</div>
      </Col>
      <Col span={6}>
      <div class="card" >
  <Link to="iot"><img style={{width:'100%'}} src={iots} alt="Avatar" /></Link>
  <div class="container">
    <h4 style={{color:'red'}}><b>IOT</b></h4>
  </div>
</div>

      </Col> 
    </Row>
</Container>
            <Footer/>
            
        </>
    )
}
export default hmi



