import React  from 'react';
import Navbar from '../../components/navbar/header'
import Footer from '../../components/footer/footer'
import { Tab,Tabs } from 'react-bootstrap';
import specs from '../../assets/hmivtspec.jpg'
import invthmivt from '../../assets/invthmivt.jpg'
import { Row,Col, Container } from 'react-bootstrap';
import vttool from '../../assets/brochers/vttool.rar'


function hmivt() {
    return (
  
        <>
        <Navbar/>
        <Row>
    <Col md={6}>
<img src={invthmivt}/>
    </Col>
        <Col md={6}>
            <Container>
<h1 style={{fontSize:'60px',paddingTop:'30px',paddingRight:'30px',color:'red'}}>VT Series HMI
</h1>
<p style={{fontSize:'30px',paddingRight:'60px'}}>VT/VK/VA series HMI is a simple operational man-machine interface with rich display, large memory and powerful configuration function, which can easily achieve the human-computer interaction function in various fields of industrial automation.</p>
<Row>
      <Col md={6}>
      <button onClick={()=>window.open(vttool)} type="button" class="btn btn-danger">VT DESIGNER TOOL</button>
      </Col>
      </Row>
</Container>
</Col>
</Row>
<Container   class="w3-container w3-teal" >
  <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
  <Tab eventKey="home" title="Features">
    <div>

    <p>● Rich screen control;</p><p>● The screen shows support for multiple languages;</p><p>● Support multiple communication links and connections between man machine and slave devices;</p><p>● 16 data recipe areas can be established;</p><p>● Multiple file operation methods, convenient access application data.</p>               
                          </div> 
    <div/>
  </Tab>
  <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
<img src={specs}/>

    <div />
  </Tab>
</Tabs>
</Container>




        <Footer/>
    </>
    )
}
export default hmivt