import React from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import Aboutusimg from '../../assets/mirranttrpt.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

function aboutus() {
    return (
      
      <div id="about" style={{backgroundColor:'#FFFFFF',borderColor:'black'}}>
       
        <Container  style={{}}>
        <Row>
    <Col md={6} >
    <Zoom><img src={Aboutusimg} style={{width:'23rem',marginTop:'5rem',marginRight:'12rem',}} alt="aboutus" /></Zoom>
    </Col>
    <Col md={6} >
    <Fade right>
    <div style={{marginTop:'1rem'}}>

    <h1 style={{color:'red',textAlign:'left'}}>ABOUT US</h1>
      <h5 style={{ whiteSpace:'break-spaces',fontFamily:'Roboto, sans-serif;',fontsize:'17px'}}>Mirrant Automation is an emerging Industrial  Automation and Control Solutions company located  at Hyderabad, as one of the leading manufacturer of Process Control  Instruments since 2015. We are an ISO 9001:2015 certified organization and manufacture a wide range of instruments such as Electromagnetic, Ultrasonic Flow meters & meters, Ultrasonic Water Meters, BTU Meters, Oval-Gear Flow Turbine Flow meters, Vortex (Steam) Flow meters, Pressure, Level, Temperature instruments and  Automation Products, PLC,HMI,SCADA,VFD  covering the    majority of Utility and Industry  applications.  We manufacture  products comparable to the best brands available in the market with over  500+  satisfied  customers  across the globe.  We also specialized in  Calibration Services and We Have NABL in-house calibration laboratory.</h5>
</div>
</Fade>
    </Col>
  </Row>
  </Container>
  
  </div>
    )
}

export default aboutus
