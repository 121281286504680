import React  from 'react';
import Navbar from '../navbar/header'
import Footer from '../footer/footer'
import {Link} from 'react-router-dom'
import plcc2b2 from '../../assets/plcc2b2.jpg'
import specc2 from '../../assets/specc2.jpg'
import { Tab,Tabs } from 'react-bootstrap';
import { Row,Col, Container } from 'react-bootstrap';
import plcc2catalog from '../../assets/brochers/plcc2catalog.pdf'
import plcc2manual from '../../assets/brochers/plcc2manual.pdf'


function plcc1() {
    return (
        <
        >
            <Navbar/>
            <Row>
            <Col md={6}>
        <img src={plcc2b2}/>
            </Col>
                <Col md={6}>
                    <Container>
        <h1 style={{fontSize:'45px',paddingTop:'30px',paddingRight:'100px',color:'red'}}>IVC2 Series Programmable Logic Controller</h1>
        <p style={{fontSize:'30px',paddingRight:'60px'}}>IVC2 series small multi-functional application PLC boasts powerful communication capacity, strong system extension capacity and stable and reliable performance.</p>
        <Row>
              <Col md={6}>
              <button  onClick={()=>window.open(plcc2catalog)} type="button" class="btn btn-danger">Catalog</button>
               < button  onClick={()=>window.open(plcc2manual)} style={{float:'right'}} type="button" class="btn btn-danger">Manual</button>
              </Col>
              </Row>
        </Container>
        </Col>
        </Row>
        <Container    class="w3-container w3-teal" >
          <Tabs   defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
          <Tab eventKey="home" title="Features">
            <div>
        
            <p>1. Max I/O points can reach up to 512 points;</p><p>2. Support up to 8 special function extension modules;</p><p>3. Can extend RS485 communication port and CANOpen communication port.</p>               
     
                                  </div> 
            <div/>
          </Tab>
          <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
        <img src={specc2}/>
        
            <div />
          </Tab>
        </Tabs>
        </Container>
  
            <Footer/>
            
        </>
    )
}
export default plcc1