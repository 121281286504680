import React  from 'react';
import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import '../vfd/vfd.css'
import {Container,Row,Col} from 'react-bootstrap'
import { Tab,Tabs } from 'react-bootstrap';
import gd20 from '../../../assets/vfd/gd20.jpg'
import gd200a from '../../../assets/vfd/gd200a.jpg'
import gd350 from '../../../assets/vfd/gd350.jpg'
import gd10 from '../../../assets/vfd/gd10.jpg'
import {Link} from 'react-router-dom'
import gd100 from '../../../assets/vfd/gd100pv.jpg'
import gd300l from '../../../assets/vfd/gd300l.jpg'
import vfdgd20 from '../../vfdgd20/vfdgd20';
import vfdgd200a from '../../vfdgd200a/vfdgd200a'
import vfdgd10 from '../../vfdgd10/vfdgd10'
import vfdgd100pv from '../../vfdgd100pv/vfdgd100pv'
import vfdgd300l from '../../vfdgd300l/vfdgd300l'
import da200 from '../../../assets/vfd/da200.jpg'
import da180 from '../../../assets/vfd/da180.jpg'
import Fade from 'react-reveal/';
import iots from '../../../assets/iots.jpg'
import invthmivs from '../../../assets/invthmivs.jpg'
import plcc1s1 from '../../../assets/plcc1s1.jpg'

function vfd() {
  return (
      <>
      <Navbar/> 
   <h2 style={{color:'red', textAlign:'center',paddingTop:'30px',fontSize:'50px'}}>Low Voltage Drive</h2>
   <Container>
<Row>
    <Col md='4'>
    <div class="card">
  <img
    src={gd20}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">GD20</h5>
    <p class="card-text">
    Series Open Loop Vector Control Drives
    </p>
    <Link to="/vfdgd20" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col >
        <Col md='4'>
    <div class="card">
  <img
    src={gd200a}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">GD200A</h5>
    <p class="card-text">
    Series General Purpose Drives
    </p>
    <Link to="/vfdgd200a" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col >
        <Col md='4'>
    <div class="card">
  <img
    src={gd10}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">GD10 
</h5>
    <p class="card-text">
    Series Mini VFD Drives
    </p>
    <Link to="/vfdgd10" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col >
    </Row>
</Container>
<h2 style={{color:'red', textAlign:'center',paddingTop:'30px',fontSize:'50px'}}>Dedicated Drive</h2>
    <Container>
    <Row>
    <Col md='4'>
    <div class="card">
  <img
    src={gd100}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">GD100-PV</h5>
    <p class="card-text">
    Series Solar Water Pump Inverter
    </p>
    <Link to="/vfdgd100pv" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col >

        </Row>
    </Container>
    <h2 style={{color:'red', textAlign:'center',paddingTop:'30px',fontSize:'50px'}}>Elevator Control System</h2>
    <Container>
    <Row>
    <Col md='4'>
    <div class="card">
  <img
    src={gd300l}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">GD300L</h5>
    <p class="card-text">
    Series Elevator Drives
    </p>
    <Link to="/vfdgd300l" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col >

        </Row>
    </Container>
    <h2 style={{color:'red', textAlign:'center',paddingTop:'30px',fontSize:'50px'}}>General Servo System</h2>

    <Container>
    <Row>
    <Col md='4'>
    <div class="card">
  <img
    src={da200}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">DA200</h5>
    <p class="card-text">
    High Performance Servo Drives    </p>
    <Link to="/vfdda200" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col >
        <Col md='4'>
    <div class="card">
  <img
    src={da180}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">DA180</h5>
    <p class="card-text">
    Economy AC Servo Drives   </p>
    <Link to="/vfdda180" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col >
        </Row>
    </Container>





    <Fade right>  <h1 style={{textAlign:'center',color:'red',marginTop:'2rem'}}>RELATED PRODUCTS</h1></Fade>
    <Container>
<Row style={{marginTop:'2rem'}}>
      <Col span={6}>
      <div class="card">
  <a  href='#/hmi'><img src={invthmivs} alt="Avatar" style={{width:'100%'}}/></a>
  <div class="container">
    <h4 style={{color:'red'}}><b>HMI</b></h4>
    
  </div>
</div>


      </Col>
      <Col span={6}>

      <div class="card">
  <a href='#/plc'><img src={plcc1s1} alt="Avatar" style={{width:'100%'}}/></a>
  <div class="container">
    <h4 style={{color:'red'}}><b>PLC</b></h4>
  </div>
</div>
      </Col>
      <Col span={6}>
      <div class="card">
  <a href='#/iot'><img src={iots} alt="Avatar" style={{width:'100%'}}/></a>
  <div class="container">
    <h4 style={{color:'red'}}><b>IOT</b></h4>
  </div>
</div>

      </Col>
      
    </Row>
</Container>
 <Footer/>
 </>
    )
}
export default vfd
