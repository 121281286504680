import React  from 'react';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Navbar from '../navbar/header'
import Footer from '../footer/footer'
import  Container  from 'react-bootstrap/Container';
import  Row  from 'react-bootstrap/Row';
import  Col  from 'react-bootstrap/Col';
import { Tab,Tabs } from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import gd201 from '../../assets/vfd/vfdgd10/vfdgd101.jpg'
import gd202 from '../../assets/vfd/vfdgd10/vfdgd102.jpg'
import gd203 from '../../assets/vfd/vfdgd10/vfdgd103.jpg'
import gd204 from '../../assets/vfd/vfdgd10/vfdgd104.jpg'
import gd205 from '../../assets/vfd/vfdgd10/vfdgd105.jpg'
import vfdgd10catalog from '../../assets/brochers/vfdgd10catalog.pdf'
import vfdgd10manual from '../../assets/brochers/vfdgd10manual.pdf'
import specs from '../../assets/vfd/vfdgd10/specs.jpg'

function vfdgd20() {
    

    return (
  
        <>
       <Navbar/>

       <Container className="container-fluid" style={{paddingTop:'0px'}}>
<Row>
  <Col md={6}>
<MDBCarousel
      activeItem={1}
      length={4}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
      width="100%"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd201}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd202}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd203}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd204}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd205}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
       
      </MDBCarouselInner>
    </MDBCarousel>
    </Col>
<Col md="6">
    <h1 style={{paddingTop:'80px'}}> GD10 Series Mini VFD Drives</h1>
    <p>GD10 mini economical vfd drives are designed for common applications of small power OEM market, with various functions of PID, multistage speed, DC braking, Modbus communication and others. Its smaller size can further reduce the installation space (the size is about 15% smaller than similar products).</p>
    <Row>
      <Col md={6}>
      <button  onClick={()=>window.open(vfdgd10manual)} type="button" class="btn btn-danger">Catalog</button>
       < button  onClick={()=>window.open(vfdgd10catalog)} style={{float:'right'}} type="button" class="btn btn-danger">Manual</button>

      </Col>
      </Row>
    </Col>

</Row>
</Container>
<div style={{paddingTop:'30px'}}>
  </div>
<Container   class="w3-container w3-teal" >
  <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
  <Tab eventKey="home" title="Features">
    <div>
  <p><strong>Features：</strong><br/>1.SVPWM control<br/>2.Compact design, less installation space<br/>3.Built-in PID with 16-step speed control<br/>4.Standard potentiometer and external LED keypad<br/>5.Optional C3 filters and C2 filters<br/>6.Natural cooling (single-phase or three-phase220V 0.2-0.75kW)<br/>7.CE requirements certified</p><p><strong>Applications: </strong><br/>Packing, Food, Textile, Centrifugal Machinery, Carving Machinery, And Wire Cutting</p>               </div> 

  </Tab>
  <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
  <img src={specs}/>
    <div />
  </Tab>
 
</Tabs>
</Container>

<Footer/>
</>
    )
}
export default vfdgd20