/* eslint-disable no-restricted-globals */
import './App.css';
import {HashRouter,Switch,Route} from 'react-router-dom'
import PageGrid from './layout/pagegrid';
//import Electro from './components/productitems/electro/ele'
import Ultra from './components/productitems/ULTRASONICFLOWMETER/ultra'
import electro from './components/productitems/electro/ele'
import btumeter from './components/productitems/btumeter/btu'
import vortex from './components/productitems/vortex/vortex'
import ovelgear from './components/productitems/ovelgear/ovelgear'
import uswm from './components/productitems/uswm/uswm'
import level from './components/productitems/level/level'
import radar from './components/productitems/radar/radar'
import turbine from './components/productitems/turbine/turbine'
import vfd from './components/applicationitems/vfd/vfd'
import scada from './components/applicationitems/scada/scada'
import plc from './components/applicationitems/plc/plc'
import hmi from './components/applicationitems/hmi/hmi'
import services from './components/services/services'
import ScrollToTop from './components/ScrollToTop'
import fuel from './components/productitems/fuel/fuel'
import iot from './components/applicationitems/iot/iot'
import vfdgd20 from './components/vfdgd20/vfdgd20';
import vfdgd200a from './components/vfdgd200a/vfdgd200a';
import vfdgd10 from './components/vfdgd10/vfdgd10';
import vfdgd100pv from './components/vfdgd100pv/vfdgd100pv';
import vfdgd300l from './components/vfdgd300l/vfdgd300l';
import vfdda200 from './components/vfdda200/vfdda200';
import vfdda180 from './components/vfdda180/vfdda180';
import hmivs from './components/hmivs/hmivs';
import hmivt from './components/hmivt/hmivt';
import hmivk from './components/hmivk/hmivk';
import plcc1 from './components/plcc1/plcc1';
import plcc2 from './components/plcc2/plcc2';
import plcc3 from './components/plcc3/plcc3';


function App() {
  return (
    <>
    <HashRouter>
      <ScrollToTop />
      <Switch>
      <Route path='/' exact component={PageGrid}/>
      <Route path='/electro'  component={electro}/>
      <Route path='/ultra'  component={Ultra}/>
      <Route path='/btumeter'  component={btumeter}/>
      <Route path='/vortex'  component={vortex}/>
      <Route path='/ovelgear'  component={ovelgear}/>
      <Route path='/uswm'  component={uswm}/>
      <Route path='/level'  component={level}/>
      <Route path='/radar'  component={radar}/>
      <Route path='/turbine'  component={turbine}/>
      <Route path='/vfd'  component={vfd}/>
      <Route path='/scada'  component={scada}/>
      <Route path='/plc'  component={plc}/>
      <Route path='/hmi'  component={hmi}/>
      <Route path='/services'  component={services}/>
      <Route path='/fuel'  component={fuel}/>
      <Route path='/iot'  component={iot}/> 
      <Route path='/vfdgd20'  component={vfdgd20}/> 
      <Route path='/vfdgd200a'  component={vfdgd200a}/> 
      <Route path='/vfdgd10'  component={vfdgd10}/> 
      <Route path='/vfdgd100pv'  component={vfdgd100pv}/> 
      <Route path='/vfdgd300l'  component={vfdgd300l}/> 
      <Route path='/vfdda200'  component={vfdda200}/> 
      <Route path='/vfdda180'  component={vfdda180}/> 
      <Route path='/hmivs'  component={hmivs}/> 
      <Route path='/hmivt'  component={hmivt}/> 
      <Route path='/hmivk'  component={hmivk}/> 
      <Route path='/plcc1'  component={plcc1}/> 
      <Route path='/plcc2'  component={plcc2}/> 
      <Route path='/plcc3'  component={plcc3}/> 

  </Switch>
  </HashRouter>
  </>
  );
}

export default App;
