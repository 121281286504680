import React from 'react'
import Coverflow from 'react-coverflow';
import i1 from '../../assets/ubgroup.png'
import i2 from '../../assets/paint-logo.png'
import i3 from '../../assets/aurobindo-pharma-vector-logo.png'
import i4 from '../../assets/ltgrouplogo.jpg'
import i5 from '../../assets/HeritageLogo.jpg'
import i6 from '../../assets/ecil.png'
import  i7 from '../../assets/itc.png'
import Fade from 'react-reveal/Fade';
import '../clients/clients.css'

function clients() {
    return (
        <div >
        <Fade right><h1 style={{color:'red'}}>CLIENTS</h1></Fade>
         <Coverflow 
    width={900}
    height={300}
    infinitescroll
    displayQuantityOfSide={3}
    navigation={false}
    enableHeading={false}
  >
    <div
    //   onClick={() => fn()}
    //   onKeyDown={() => fn()}
      role="menuitem"
      tabIndex="0"
      
    >
      <img
        src={i3}
        alt='title or description'
        style={{ display: 'block', width: '100%',backgroundColor:'red' }}
      />
    </div>
    <img src={i2} alt='title or description' />
    <img src={i1} alt='title or description' />
    <img src={i7} alt='title or description' />
    <img src={i4} alt='title or description' />
    <img src={i5} alt='title or description' />
    <img src={i6} alt='title or description' />



  </Coverflow>
            
        </div>
    )
}

export default clients
