import React  from 'react';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Navbar from '../navbar/header'
import Footer from '../footer/footer'
import  Container  from 'react-bootstrap/Container';
import  Row  from 'react-bootstrap/Row';
import  Col  from 'react-bootstrap/Col';
import gd201 from '../../assets/vfd/vfdsd300l/vfdsd300l.jpg'
import gd202 from '../../assets/vfd/vfdsd300l/vfdsd300l1.jpg'
import { Tab,Tabs } from 'react-bootstrap';
import specs from '../../assets/vfd/vfdsd300l/specs.jpg'

import vfdgd300lcatalog from '../../assets/brochers/vfdgd300lcatalog.pdf'
import vfdgd300lmanual from '../../assets/brochers/vfdgd300lmanual.pdf'





function vfdgd300l() {
    

    return (
  
        <>
              <Navbar/>

       <Container className="container-fluid" style={{paddingTop:'0px'}}>
<Row>
  <Col md={6}>
<MDBCarousel
      activeItem={1}
      length={2}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
      width="100%"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd201}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd202}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem>
        
       
      </MDBCarouselInner>
    </MDBCarousel>
    </Col>
<Col md="6">
    <h3 style={{paddingTop:'100px'}}>GD300L Series Elevator Drives
</h3>
    <p>                      GD300L series drives are the new-generation inverters developed based on CHV180 series inverters with GD300 control platform. As using TI 28-series DSP and advanced vector control technology. GD300L elevator drives improved great achievements in security and reliability, control performance, and use functions.                 </p>
    <Row>
      <Col md={6}>
      <button  onClick={()=>window.open(vfdgd300lcatalog)} type="button" class="btn btn-danger">Catalog</button>
       < button  onClick={()=>window.open(vfdgd300lmanual)} style={{float:'right'}} type="button" class="btn btn-danger">Manual</button>

      </Col>
      </Row>
    </Col>

</Row>
</Container>
<div style={{paddingTop:'30px'}}>
  </div>
<Container   class="w3-container w3-teal" >
  <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
  <Tab eventKey="home" title="Features">
    <div>
    <p><strong>Safety</strong><br/>(1)Safe Torque Off(STO)<br/>(2)Forced deceleration<br/>(3)Emergency running<br/>(4)Brake and contactor control</p><p><strong>Comfort</strong><br/>(1)Starting torque compensation with weight sensors<br/>(2)Starting torque compensation without weight sensors<br/>(3)ACC/DEC S curve algorithm<br/>(4)Optimized ASR</p><p><strong>Ease of use</strong><br/>(1)Ability to drive asynchronous and synchronous motors<br/>(2)Support open loop control mode without encoder and closed loop control mode with encoder<br/>(3)Static identification on the initial pole angle of a synchronous motor<br/>(4)Support multiple types of encoder<br/>(5)Energy-saving running mode<br/>(6)Monitor and control by upper computer software<br/>(7)Optional LCD keypad</p>          </div>

  </Tab>
  <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
  <img src={specs}/>
    <div />
  </Tab>
 
</Tabs>
</Container>

<Footer/>
</>
    )
}
export default vfdgd300l