import React from 'react'
import {Navbar,Nav,NavDropdown} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import logo from '../../assets/MIRRANTNEWIMG.JPG'
import { NavHashLink } from 'react-router-hash-link';
import './header.css'
import Zoom from 'react-reveal/Zoom';

function header() {
    return (
      
      <Navbar collapseOnSelect expand="lg" bg="black"  variant="white">
      <Zoom><Navbar.Brand ><img  style={{display:'inline-block'}} className="header_logo" alt="mirrant" src={logo} /><p style={{display:'inline-block',fontSize:'18px',color:'red'}}>MIRRANT AUTOMATION PRIVATE LIMITED</p></Navbar.Brand></Zoom>
      <Navbar.Toggle style={{backgroundColor:'red'}} className="toggle" aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link> <Link to="/"><h6 className='home' ><b style={{color:'black',margin:'0px 20px'}}>HOME</b></h6></Link> </Nav.Link>

          <NavDropdown style={{marginTop:'-2px',margin:'-2px 15px'}}   title={<b>PRODUCTS</b>} id="collasible-nav-dropdown">
          <NavDropdown.Item ><Link to="/electro"><h6 style={{color:'red'}} >ELECTROMAGNETIC FLOW METER</h6></Link></NavDropdown.Item>

          <NavDropdown.Item ><Link to="/uswm"><h6 style={{color:'red'}}>ULTRASONIC WATER METER</h6></Link></NavDropdown.Item>
          <NavDropdown.Item ><Link to="/ultra"><h6 style={{color:'red'}}>ULTRASONIC FLOW METER</h6></Link></NavDropdown.Item>
            <NavDropdown.Item ><Link to="/vortex"><h6 style={{color:'red'}}>VORTEX FLOW METER</h6></Link></NavDropdown.Item>
            <NavDropdown.Item ><Link to="/ovelgear"><h6 style={{color:'red'}}>OVAL-GEAR FLOW METER</h6></Link></NavDropdown.Item>
            <NavDropdown.Item  ><Link to="/turbine"><h6 style={{color:'red'}}>TURBINE FLOW METER</h6></Link></NavDropdown.Item>
            <NavDropdown.Item ><Link to="/btumeter"><h6 style={{color:'red'}}>BTU METER</h6></Link></NavDropdown.Item>
            <NavDropdown.Item ><Link to="/level"><h6 style={{color:'red'}}>ULTRASONIC LEVEL TRANSMITTER</h6></Link></NavDropdown.Item>
            <NavDropdown.Item ><Link to="/radar"><h6 style={{color:'red'}}>RADAR LEVEL TRANSMITTER</h6></Link></NavDropdown.Item> 
            <NavDropdown.Item ><Link to="/fuel"><h6 style={{color:'red'}}>FUEL DISPENSER</h6></Link></NavDropdown.Item> 

          </NavDropdown>
          <NavDropdown style={{marginTop:'-5px',margin:'-2px 15px'}}  title={<b>AUTOMATION</b>} id="collasible-nav-dropdown">
          <NavDropdown.Item  ><Link to="/plc"><h6 style={{color:'red'}}>PLC</h6></Link></NavDropdown.Item>
          <NavDropdown.Item  ><Link to="/hmi"><h6 style={{color:'red'}}>HMI</h6></Link></NavDropdown.Item>

          <NavDropdown.Item ><Link to="/vfd"><h6 style={{color:'red'}}> VFD</h6></Link></NavDropdown.Item>
          <NavDropdown.Item ><Link to="/iot"><h6 style={{color:'red'}}> IOT</h6></Link></NavDropdown.Item>




          
            </NavDropdown>
            <Nav.Link style={{margin:'0px 15px'}} ><Link to="/services"><h6><b style={{color:'black'}}>SERVICES</b></h6></Link></Nav.Link>

            <NavHashLink style={{margin:'0px 15px'}} to="#footer" smooth={true}><Nav.Link ><h6 href='/footer' style={{marginRight:'0rem',color:'black'}}><b>CONTACTUS</b></h6></Nav.Link></NavHashLink>
            <Nav.Link ><h6 onClick={()=>window.open("https://mirrantcloud.com/login")}><b style={{color:'black'}}>LOGIN</b></h6></Nav.Link>


        </Nav>
        
      </Navbar.Collapse>
    </Navbar>

            
        
    )
}

export default header
