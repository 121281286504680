import React  from 'react';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Navbar from '../navbar/header'
import Footer from '../footer/footer'
import  Container  from 'react-bootstrap/Container';
import  Row  from 'react-bootstrap/Row';
import  Col  from 'react-bootstrap/Col';
import gd201 from '../../assets/vfd/vfdgd200a/vfdgd200a1.jpg'
import gd202 from '../../assets/vfd/vfdgd200a/vfdgd200a2.jpg'
import gd203 from '../../assets/vfd/vfdgd200a/vfdgd200a3.jpg'
import gd204 from '../../assets/vfd/vfdgd200a/vfdgd200a4.jpg'

import { Tab,Tabs } from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import specs from '../../assets/vfd/vfdgd200a/specs.jpg'
import vfdgd200acatalog from '../../assets/brochers/vfdgd200acatalog.pdf'
import vfdgd200amanual from '../../assets/brochers/vfdgd200amanual.pdf'



function vfdgd20() {
    

    return (
  
        <>
               <Navbar/>

     <Container className="container-fluid" style={{paddingTop:'0px'}}>
<Row>
  <Col md={6}>
<MDBCarousel
      activeItem={1}
      length={4}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
      width="100%"
      
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd201}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd202}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd203}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd204}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
       
      </MDBCarouselInner>
    </MDBCarousel>
    </Col>
<Col md="6">
    <h3 style={{paddingTop:'100px'}}>GD200A Series General Purpose Drives</h3>
    <p>Based on DSP control system, INVT GD200A drives apply V/F vector control and various protections to control asynchronous motors. The air duct, hardware and software have been greatly improved for better environment adaptability, operation and reliable quality certificated by TÜV SÜD.</p>
    <Row>
      <Col md={6}>
      <button onClick={()=>window.open(vfdgd200acatalog)}  type="button" class="btn btn-danger">Catalog</button>
       < button  onClick={()=>window.open(vfdgd200amanual)} style={{float:'right'}} type="button" class="btn btn-danger">Manual</button>

      </Col>
      </Row>
    </Col>

</Row>
</Container>
<div style={{paddingTop:'30px'}}>
  </div>
<Container   class="w3-container w3-teal" >
  <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
  <Tab eventKey="home" title="Features">
  <div class="con_tab"> 
               <div class="box_text">
                    <p><strong>
 High performance</strong><br/>
 1.More Accurate Motor Autotuning<br/>
 2.Advanced open loop vector control<br/>
 3.Perfect voltage and current control, reducing the fault protection times<br/>4.Multiple braking modes and instant stopping<br/>
 5.0.75kW~500kW(Three Phase 400V)</p><p><strong>Features</strong><br/>
 1.VF and Sensorless Vector Control<br/>
 2.Up to 0.5Hz/150% starting torque<br/>
 3.Built-in braking unit (≤30kW), Optional external braking unit (≥37kW)<br/>
 4.External LED keypad to copy parameters(≥18.5kW), Standard membrane keypad design(≤15kW), Optional LCD keypad provided<br/>
 5.Standard C3 filters; Optional C2 filters<br/>
 6.Accurate parameters auto tuning and better motor drive<br/>
 7.Simple water supply function<br/>
 8.Support G/P dual rating<br/>
 9.Customization services provided (like PTC )<br/>
 10.CE requirements certified</p><p><strong>
 Applications</strong><br/>
 Air Compressor, Oil, Warming and Water Supply, Plastics Machine, Mine, Fans and Water Pumps.</p>               </div>      
</div>
    <div />
  </Tab>
  <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
  <img src={specs}/>
    <div />
  </Tab>
 
</Tabs>
</Container>


<Footer/>
</>
    )
}
export default vfdgd20