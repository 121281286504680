import React  from 'react';
import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import '../iot/iot.css'
import Iotcarousel from '../../iotcarousel/iotcarousel'
import {Container,Row,Col} from 'react-bootstrap'
import Bms from '../../../assets/iot/bms.png'
import Ems from '../../../assets/iot/ems.PNG'
import Wms from '../../../assets/iot/smw.png'
import Dash from '../../../assets/iot/dash.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Iot1 from '../../../assets/iot/iot1.PNG'
import Iot2 from '../../../assets/iot/iot2.PNG'
import Login from '../../../assets/iot/login.PNG'
import excel from '../../../assets/iot/excel.PNG'
import { Tab,Tabs } from 'react-bootstrap';
import Energy from '../../../assets/iot/energy.png'
import Water from '../../../assets/iot/water.jpg'
import Building from '../../../assets/iot/building.png'
import Slide from 'react-reveal/Slide';
import Iottrend from '../iot/iottrend'
import Iotbar from '../iot/iotbar'
import Iotpie from '../iot/iotpie'
import Reveal from 'react-reveal/Reveal';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardSubTitle, MDBCardText, MDBCardLink } from 'mdb-react-ui-kit';
import iots from '../../../assets/iots.jpg'




function iot() {

  
    return (
      
     
        <>

<Navbar/>
<Iotcarousel/>
  <h1 style={{color:'red',marginTop:'20px'}}>Key Applictions</h1>
  <div style={{backgroundColor:'#fafafa'}}>
  <Container style={{backgroundColor:'#fafafa'}}>
<Row style={{marginTop:'20px'}}>
        <Col sm={4} >
          <img className="application-img" src={Bms} alt="apps" />
          <h4 style={{paddingTop:'10px'}}>Building Management System</h4>
        </Col>
        <Col sm={4} >
        <img className="application-img" src={Ems} alt="apps" />
        <h4 style={{paddingTop:'10px'}}>Energy Management System</h4>
        </Col>
        <Col sm={4} >
        <img className="application-img" src={Wms} alt="apps" />
        <h4 style={{paddingTop:'10px'}}>Water Management System</h4>
        </Col>
     </Row>
     </Container>
     </div>





     <div style={{paddingTop:'40px',backgroundColor:'#fafafa'}}>
       </div>
     <div  className="main">
  <Container  className="contain" >
  <Tabs  defaultActiveKey="profile" id="uncontrolled-tab-example" className="lg6">
  <Tab eventKey="home" title="WATER">
    <Row>
  <Col md={6}>
  <Slide left>

  <img src={Water} style={{width:'25rem'}} alt="aboutus" />
  </Slide>
      </Col>
      <Col md={6}>
  <h1 style={{color:'red',textAlign:'left',marginTop:'5rem'}}>Water Management System</h1>
  <p style={{}}>Mirrant is focussed towards committing its technologies & products to solve the water problems of India by digitizing the entire water infrastructure. We help water authorities to smartly monitor & analyze the water flow, pressure, level, pumping across the entire water distribution network to minimize monetary losses, wastage & operational inefficiencies. We have helped utilities & public companies to smartly automate pumping stations, improve efficiency of treatment plants, smartly manage the water availability etc.
</p>
</Col >
</Row>
    <div />
  </Tab>
  <Tab style={{fontcolor:'red'}} eventKey="profile" title="BUILDING">
  <Row>
    <Col md={6}>
    <Slide left>

    <img src={Energy} style={{width:'30rem'}} alt="aboutus" />
    </Slide>
      </Col>
      <Col md={6}>
  <h1 style={{color:'red',textAlign:'left',marginTop:'5rem'}}>Building Management System</h1>

  <p>Cloud provides cutting edge energy management for infrastructures like factories, commercial buildings, retail stores, electric utilities, government infrastructures etc. It is already empowering the enterprises to reduce cost by smartly automating asset operations, defining data driven maintenance strategies, reducing penalties etc. The software leverages the energy meter data to isolate the energy inefficiency, wastage, poor power quality & other problem areas.

</p>
</Col >
</Row>
   

    <div />
  </Tab>
  <Tab eventKey="contact" title="ENERGY" >
  <Row>
    <Col md={6}>
    <Slide left>

    <img  src={Building} style={{width:'25rem'}} alt="aboutus" />
    </Slide>
      </Col>
      <Col md={6}>
  <h1 style={{color:'red',textAlign:'left',marginTop:'5rem'}}>Energy Management System</h1>

  <p>Mirrant provides cutting edge energy management for infrastructures like factories, commercial buildings, retail stores, electric utilities, government infrastructures etc. It is already empowering the enterprises to reduce cost by smartly automating asset operations, defining data driven maintenance strategies, reducing penalties etc. The software leverages the energy meter data to isolate the energy inefficiency, wastage, poor power quality & other problem areas.

</p>
</Col >
</Row>
    <div />
  </Tab>
</Tabs>
</Container>
</div>







     <Container  style={{}}>
        <Row>
    <Col md={6} >
    <Zoom><img src={Dash} style={{width:'35rem',marginTop:'5rem',marginRight:'5em',}} alt="aboutus" /></Zoom>
    </Col>
    <Col md={6} >
    <Fade right>
    <div style={{marginTop:'1rem'}}>

    <h1 style={{color:'red',textAlign:'left',marginTop:'5rem'}}>What makes Mirrant relevant?
</h1>
      <h5 style={{ whiteSpace:'break-spaces',fontFamily:'Roboto, sans-serif;',fontsize:'17px'}}>Mirrantcloud is a powerful  platform that is ideal to drive digital transformation for any enterprise. It is modular, flexible & scalable to solve the present and future operational problems. It provides multi-tenancy & multi hierarchy to improve data consumption. Further, I/O Sense offers features like reports, alerts, visualization, drag & drop dashboards, library of analytical widgets, tools for machine learning & environment for co-development for visionary enterprises.</h5>
</div>
</Fade>
    </Col>
  </Row>
  </Container>






  <h1 className="gradiant" style={{textAlign:'center',color:'red'}}>Our Dashboard</h1>

  
  <Container>
 
  <Row>
    <Col  md={6}>
    <h3 style={{paddingBottom:'20px'}} className="mt-5">Location Of Flowmeter</h3>

    <iframe  title='location' class="location" src = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.563214135347!2d78.27160581470532!3d17.528351887994223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbf30ac67418a9%3A0xbb28cfea3ba3e3b6!2sMIRRANT%20AUTOMATION%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1608045652013!5m2!1sen!2sin'></iframe>
  </Col>
  <Col md={6}>
  <Slide right>

  <Iottrend/>
  </Slide>
    </Col>
  </Row>
  <Row>
    <Col md={6}>
    <Slide left>

    <Iotpie/>
    </Slide>

  </Col>
  <Col md={6}>
  <Slide right>

    <Iotbar/>
    </Slide>
    </Col>
  </Row>
  </Container>
  <div className="hey" style={{paddingTop:'120px',paddingBottom:'120px'}}>
  <Container>

  <Row>
  <Col md={6}>
  <Reveal fadeInUp>
  <h1>       
<b style={{fontSize:'100px'}}>Hey!</b><br></br><b  style={{fontSize:'100px'}}>You Can</b> <br></br><b style={{color:'red',fontSize:'50px'}}> Login Here.</b></h1>
  </Reveal>
  </Col>
  <Col style={{paddingLeft:'100px'}} md={6}>
 

  <div  class="card">
  <img
    src={iots}
    
  />
  <div class="card-body">
    <h5  class="card-title">MIRRANT CLOUD</h5>
   
    < Row style={{paddingTop:'20px'}}>
      <Col md={6}>
    <a href="https://mirrantcloud.com/login" class="btn btn-dark">LOGIN</a>
    </Col>
    <Col style={{paddingRight:'30px'}} md={3}>

    <a href="https://mirrantcloud.com/register" class="btn btn-danger">REGISTER</a>
    </Col>
</Row>
  </div>
</div>
 


 

  </Col>

    </Row>
    </Container>

</div>
<Footer/>


  

 </>
 
    )
   

   
 
}




export default iot


