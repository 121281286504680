import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import '../ovelgear/ovelgear.css'
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import { Row,Col,Container} from 'react-bootstrap'
import Fade from 'react-reveal/Fade';
import ovel1 from '../../../assets/ovel/ovel (1).jpg'
import ovel2 from '../../../assets/ovel/ovel (2).jpg'
import ovel3 from '../../../assets/ovel/ovel (3).jpg'
import ovel4 from '../../../assets/ovel/ovel (4).jpg'
import ovel5 from '../../../assets/ovel/ovel (5).jpg'
import ovel6 from '../../../assets/ovel/ovel (6).jpg'
import ovel7 from '../../../assets/ovel/ovel (7).jpg'
import ovel8 from '../../../assets/ovel/ovel (8).jpg'
import ovel9 from '../../../assets/ovel/ovel (9).jpg'
import ovel10 from '../../../assets/ovel/ovel (10).jpg'
import ovel11 from '../../../assets/ovel/ovel (11).jpg'
import ovel12 from '../../../assets/ovel/ovel (12).jpg'
import Products from '../../products/products'
import ovelgearpdf from '../../../assets/brochers/ovelgear.pdf'


    const CarouselPage = () => {
      
  return (
    <>

           <Navbar />
           
      <Row>
        <Col sm={6}>
        <MDBCarousel
      activeItem={1}
      length={12}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel1}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel2}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel3}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel4}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel5}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="6">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel6}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="7">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel7}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="8">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel8}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="9">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel9}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="10">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel10}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="11">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel11}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="12">
          <MDBView>
            <img
              className="d-block w-100"
              src={ovel12}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
    <button onClick={()=>window.open(ovelgearpdf)}  class="btnn" style={{width:'70%',marginRight:'0rem',marginTop:'2rem',marginLeft:'4rem'}}><i class="fa fa-download"></i>DOWNLOAD TECHNICAL DATASHEET</button>

        </Col>
        <Col  sm={6}>
        
        <h2 className='headername' style={{color:'red',marginTop:'3rem',marginLeft:'5rem'}}>OVAL-GEAR FLOW METER</h2>
        <Fade bottom>
        <p style={{marginLeft:'5rem',marginBottom:'2rem',marginRight:'5rem'}} >
        Oval gear meters are displacement-type volume meters that transport defined incremental
volumes in individual measuring chambers. The measuring element consists of two high precision toothed oval gears, which are driven by the flow of the medium and mesh with each
other. In this way, a defined volume is transported for each rotation of the pair of oval gears.
The number of rotations is a measure of the amount of fluid that has passed through the meter. The rotations are detected by a sensor element.
A very precisely adjusted gear pair within the casing forms the measuring element. The in
flowing medium causes the gear pair to rotate. The rotary motion is scanned by contactless
sensors. Since each individual tooth generates a pulse, this results in a very high resolution.
Consequently, even the smallest volumes can be measured or dosed precisely.
The measurement unit contains two pick-offs that are circumferentially offset by of a tooth
pitch to generate a 2 channel flow-proportional frequency signal.
Suitable processing of the signal provides an greater resolution and the option to identify the
flow direction. Basically, the measurement accuracy increases with increase in viscosity of
the media.
The oval gear flow meter comprises oval-shaped, geared rotors which rotate within a housing of specified geometry. Fluid differential pressure causes the intermeshing gears to rotate,
trapping a ‘pocket’ of fluid between the gear and the outer housing and subsequently emptying the fluid pocket into the downstream flow
        </p>
        </Fade>
        </Col>
        </Row>
        
        
        
     
        
        <Fade bottom>
    <Container>
    <h1 style={{color:'red'}}>SPECIFICATION</h1>
    <table style={{fontSize:'14px'}}> 
      <tr>
        <th>Medium
    </th>
        <th>
        Diesel,Kerosene, Gasoline, Light Oils, Heavy Oils, Food Oils, Solvents Lubrication Oils, Chemicals, Pharma Chemicals, Clear Water etc</th>
      </tr>
      <tr>
        <td>Diameter</td>
        <td>DN3~DN200mm</td>
      </tr>
      <tr>
        <td>Accuracy</td>
        <td>±0.5%

    </td>
      </tr>
      <tr>
        <td>Repeatability</td>
        <td>±0.1% of Span

    </td>
      </tr>
      <tr>
        <td>Medium temperature</td>
        <td>up to 80’C
</td>
        
      </tr>
      <tr>
        <td>Medium pressure (Kg/Cm2)</td>
        <td>up to 50 Bar
    </td>
    
      </tr>
      <tr>
        <td>Body</td>
        <td>Aluminium,SS304,SS316
</td>
      </tr>
      
      <tr>
        <td>Rotor </td>
        <td>Aluminium,SS316,PPS</td>
      </tr>
      <tr>
        <td>O-ring </td>
        <td>NBR, Viton
</td>
      </tr>
      <tr>
        <td>Shaft</td>
        <td>SS316</td>
      </tr>
      <tr>
        <td>Electronic Enclosure</td>
        <td>Aluminium, Plastic</td>
        
      </tr>
      <tr>
        <td>Power supply</td>
        <td>5-24VDC,Battery</td>
    
      </tr>
      <tr>
        <td>Signal output </td>
        <td>4～20mA, Pulse-NPN and Relay 
</td>
      </tr>
      <tr>
        <td>Communication</td>
        <td>RS485,GSM/GPRS & LORA/NB-IOT
</td>
      </tr>
      <tr>
        <td>Display</td>
        <td>LCD Display In Engg.Units</td>
      </tr>
      <tr>
        <td>Explosion-proof</td>
        <td>FLP
    </td>
      </tr>
      <tr>
        <td>Protection proof</td>
        <td>IP65,IP67& IP68
    </td>
      </tr>
      <tr>
        <td>Flow Transmitter</td>
        <td>Integral, Remote
    </td>
      </tr>
      <tr>
        <td>Work environment </td>
        <td>Ambient temperature：-20～+60 ‘C，Ambient humidity：5%～85%
    
    </td>
      </tr>
      <tr>
        <td>End Connection</td>
        <td>Thread-end, Flange-end and Tri-clover 
    </td>
      </tr>
     
      
    </table>
    </Container>
     
           
            
   <Products/>
            </Fade>
            <Footer/>
        </>
        );
      
    }
    
         
        
    
              export default CarouselPage;

    
  


              