import React  from 'react';
import Navbar from '../navbar/header'
import Footer from '../footer/footer'
import {Link} from 'react-router-dom'
import plcc1b1 from '../../assets/plcc1b1.jpg'
import specc1 from '../../assets/specc1.jpg'
import { Tab,Tabs } from 'react-bootstrap';
import { Row,Col, Container } from 'react-bootstrap';
import plcc1catalog from '../../assets/brochers/plcc1catalog.pdf'
import plcc1manual from '../../assets/brochers/plcc1manual.pdf'


function plcc1() {
    return (
        <
        >
            <Navbar/>
            <Row>
            <Col md={6}>
        <img src={plcc1b1}/>
            </Col>
                <Col md={6}>
                    <Container>
        <h1 style={{fontSize:'45px',paddingTop:'30px',paddingRight:'100px',color:'red'}}>IVC1L Series Programmable Logic Controller</h1>
        <p style={{fontSize:'30px',paddingRight:'60px'}}>INVT IVC1 PLC is a micro high performance PLC with compact structure and powerful function. It can be widely applied in manufacturing industries including textile and chemical fiber, machine tool, cables, food and beverages, packaging, plastic steel, construction machinery, air conditioner, elevator and printing.</p>
        <Row>
              <Col md={6}>
              <button   onClick={()=>window.open(plcc1catalog)} type="button" class="btn btn-danger">Catalog</button>
               < button   onClick={()=>window.open(plcc1manual)}  style={{float:'right'}} type="button" class="btn btn-danger">Manual</button>
              </Col>
              </Row>
        </Container>
        </Col>
        </Row>
        <Container style={{paddingTop:'40px'}}  class="w3-container w3-teal" >
          <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
          <Tab eventKey="home" title="Features">
            <div>
        
            <p><strong>Main features</strong><br/>MAX. IO points: 128<br/>Standard 3 isolated serial ports:1 RS232/2 RS485<br/>Support 6 high speed pulse input<br/>Support 3x100kHz high speed pulse output<br/>Program capacity: 16K steps<br/>Program power-off permanent storage<br/>Equipped with rechargeable lithium battery, 
real-time clock saved for 3 years.<br/>Max. 7 extension module<br/>The main module is divided into two kinds: 
DC power supply（19-30VDC）and AC power supply（85-264VAC）</p>           
                                  </div> 
            <div/>
          </Tab>
          <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
        <img src={specc1}/>
        
            <div />
          </Tab>
        </Tabs>
        </Container>

  
            <Footer/>
            
        </>
    )
}
export default plcc1