import React from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Footer from '../../footer/footer'

import Fade from 'react-reveal/Fade';

import "react-multi-carousel/lib/styles.css";
import Navbar from '../../navbar/header'
import emf1 from '../../../assets/emf/emf1.jpg'
import emf2 from '../../../assets/emf/emf2.jpg'
import emf3 from '../../../assets/emf/emf3.jpg'
import emf4 from '../../../assets/emf/emf4.jpg'
import emf5 from '../../../assets/emf/emf5.jpg'
import emf6 from '../../../assets/emf/emf6.jpg'
import emf7 from '../../../assets/emf/emf7.jpg'
import emf8 from '../../../assets/emf/emf8.jpg'
import emf9 from '../../../assets/emf/emf9.jpg'
import Products from '../../products/products'
import './ele.css'
import { Row,Col,Container} from 'react-bootstrap'
import emfpdf from '../../../assets/brochers/emf.pdf'






const CarouselPage = () => {
  return (
    <>
       <Navbar />
       
  <Row>
    <Col sm={6}>
    <MDBCarousel
      activeItem={1}
      length={9}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={emf1}
              alt=''
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={emf2}
              alt=""
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={emf6}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={emf4}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={emf8}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="6">
          <MDBView>
            <img
              className="d-block w-100"
              src={emf3}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="7">
          <MDBView>
            <img
              className="d-block w-100"
              src={emf7}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="8">
          <MDBView>
            <img
              className="d-block w-100"
              src={emf5}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="9">
          <MDBView>
            <img
              className="d-block w-100"
              src={emf9}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>            
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
<button onClick={()=>window.open(emfpdf)} class="btnn" style={{width:'70%',marginRight:'3rem',marginTop:'2rem',marginLeft:'7rem'}}><i class="fa fa-download"></i>DOWNLOAD TECHNICAL DATASHEET</button>
    </Col>
    <Col  sm={6}>
      
    <h2 className='headername' style={{color:'red',marginTop:'3rem',marginLeft:'5rem'}}>ELECTROMAGNETIC FLOW METER</h2>
    <Fade bottom>
    <p style={{marginLeft:'5rem',marginBottom:'2rem',marginRight:'5rem',fontSize:'17px'}} >
    The working principle of a magnetic flow meter is based on Faraday’s law of electromagnetic
induction. <br></br>According to Faraday’s law, when the conductive fluid flows through a magnetic
field of the sensor, an electromotive force proportional to the volume flow is generated between the pair of electrodes, which is perpendicular to the flow direction and the magnetic
field.<br></br> The amplitude of the electromotive force can be expressed as: 
<br></br>
E = kBDv <br></br>
Where E:Induced electric potential,<br></br>
 K: constant,<br></br>
 B:Magnetic flux density,<br></br>
 D:The inner diameter of the measuring Tube,<br></br>
 V:The average velocity of the fluid in the<br></br>
axial direction of the electrode cross-section inside<br></br>
the measuring tube. <br></br>
    </p>
    </Fade>
    </Col>
    </Row>
    
    
    
 
    <Fade bottom>
    
<Container>
<h1 style={{color:'red'}}>SPECIFICATION</h1>
<table   style={{fontSize:'18px'}}>
  <tr >
    <th>Medium
</th>
    <th>Domestic water, industrial water, raw water, ground water, urban
sewage, industrial waste water, Processed neutral pulp, pulp slurry,
Chemicals etc.</th>
  </tr>
  <tr>
    <td style={{width:'25%'}}>Diameter</td>
    <td>DN10~DN2000mm</td>
  </tr>
  <tr>
    <td>Accuracy</td>
    <td>±0.5%
</td>
  </tr>
  <tr>
    <td>Repeatability</td>
    <td>±0.2% of Span
</td>
  </tr>
  <tr>
    <td>Medium temperature</td>
    <td>＜180'C</td>
    
  </tr>
  <tr>
    <td>Medium pressure (Kg/Cm2)</td>
    <td>10,16,25,40
</td>

  </tr>
  <tr>
    <td>Flow rate</td>
    <td>0.3～10m/s</td>
  </tr>
  
  <tr>
    <td>Electrode Material </td>
    <td>SS316 Std.,Hastalloy C,Titanium,Tantalum,Platinum,etc</td>
  </tr>
  <tr>
    <td>Lining Material </td>
    <td>PTFE,Rubber,Neoprene,PFA,HDPE,Polyurethane,CPVC etc</td>
  </tr>
  <tr>
    <td>Flow Tube Material</td>
    <td>SS304 Std,SS316</td>
  </tr>
  <tr>
    <td>Coil House Material</td>
    <td>Carbon steel,Stainless steel</td>
    
  </tr>
  <tr>
    <td>Power supply</td>
    <td>230VAC,24VDC,Battery</td>

  </tr>
  <tr>
    <td>Signal output</td>
    <td>4～20mA, Pulse and Relay</td>
  </tr>
  <tr>
    <td>Communication Interface</td>
    <td>RS485,GSM/GPRS,HART,LORA</td>
  </tr>
  <tr>
    <td>Display</td>
    <td>LCD Display In Engg.Units</td>
  </tr>
  <tr>
    <td>Protection proof</td>
    <td>IP65; IP67,IP68
</td>
  </tr>
  <tr>
    <td>Flow Transmitter</td>
    <td>Integral, Remote
</td>
  </tr>
  <tr>
    <td>Enclosure Material</td>
    <td>Die Cast Aluminium
</td>
  </tr>
  <tr>
    <td>Electrical Connection</td>
    <td>1/2" NPT</td>


  </tr>
  <tr>
    <td>Work environment</td>
    <td>Ambient temperature：-20～+60H，Ambient humidity：5%～90%
</td>
  </tr>
  <tr>
    <td>End Connection</td>
    <td>Flange-end, Wafer, Tri-Clover etc.</td>
  </tr>
  
</table>
</Container>
 
       <Products/>
        </Fade>

       <Footer/>
    

    
     
    
</>
    
  );
  
}

export default CarouselPage;