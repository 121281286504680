import React from 'react'
import { MDBCarousel, MDBCarouselCaption,MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, } from
"mdbreact";
import Footer from '../../footer/footer'
import Products from '../../products/products'
import "react-multi-carousel/lib/styles.css";
import Navbar from '../../navbar/header'
import '../ULTRASONICFLOWMETER/ultra.css'
import { Row,Col,Container} from 'react-bootstrap'
import Fade from 'react-reveal/Fade';
import ultra1 from '../../../assets/ultra/ultra1.jpg'
import ultra2 from '../../../assets/ultra/ultra2.jpg'
import ultra3 from '../../../assets/ultra/ultra3.jpg'
import ultra4 from '../../../assets/ultra/ultra4.jpg'
import ultra5 from '../../../assets/ultra/ultra5.jpg'
import ultra6 from '../../../assets/ultra/ultra6.jpg'
import ultra7 from '../../../assets/ultra/ultra7.jpg'
import ultra8 from '../../../assets/ultra/ultra8.jpg'
import ultra9 from '../../../assets/ultra/ultra9.jpg'
import ultrapdf from '../../../assets/brochers/ultra.pdf'





const CarouselPage = () => {
  return (
    <>
       <Navbar />
  <Row>
    <Col md={6}>
    <MDBCarousel
      activeItem={1}
      length={9}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
      <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={ultra1}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
      <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={ultra2}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={ultra8}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={ultra4}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={ultra5}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="6">
          <MDBView>
            <img
              className="d-block w-100"
              src={ultra6}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="7">
          <MDBView>
            <img
              className="d-block w-100"
              src={ultra7}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="8">
          <MDBView>
            <img
              className="d-block w-100"
              src={ultra9}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="9">
          <MDBView>
            <img
              className="d-block w-100"
              src={ultra3}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>

      </MDBCarouselInner>
    </MDBCarousel>
    <button onClick={()=>window.open(ultrapdf)} class="btnn" style={{width:'70%',marginRight:'3rem',marginTop:'2rem',marginLeft:'7rem'}}><i class="fa fa-download"></i>DOWNLOAD TECHNICAL DATASHEET</button>
    </Col>
    <Col  md={6}>
      
    <h2 className='headername' style={{color:'red',marginTop:'3rem',marginLeft:'5rem'}}>ULTRASONIC FLOW METER</h2>
    <Fade bottom>

    <p style={{marginLeft:'5rem',marginBottom:'2rem',marginRight:'5rem',fontSize:'17px'}} >
    The working principle of a Ultrasonic flow meter is based on the transit-time Technology.The ultrasonic flow meter utilizes two ultrasonic transducers that function as both ultrasonictransmitter and receiver. The ultrasonic flow meter operates by alternately transmitting and
receiving a burst of ultrasound between the two transducers by measuring the transit time
that it takes for sound to travel between the two transducers in both directions. The difference in the transit time t measured is directly proportional to the velocity ( V) of the liquid
in the pipe.
With no flow, the transit time will be equal in both directions. With flow ultrasound will <br></br>
travel
faster in direction of flow &slower against the flow. The liquid velocity <br></br>
(V) inside the pipe
can be related to the difference in time of flight through the following equation 
<br></br>

V = K x Dx t <br></br>

Where V: Velocity of the Liquid <br></br>

 K: Constant <br></br>

 D: Distance between the two transducers <br></br>

 t: Difference in transmit time <br></br>

    </p>
    </Fade>
    </Col>
    </Row>
    
    
 
    
    <Fade button>
    

<h1 style={{color:'red'}}>SPECIFICATION</h1>
<Container>
<table style={{fontsize:'20px'}}>
  <tr      >
    <th>Medium
</th>
    <th>Domestic water, industrial water, raw water, ground water,
sewage with small particle content, Oils, Chemicals Alcohol,
acids, Beverage, Liquid food.,etc</th>
  </tr>
  <tr>
    <td style={{width:'20%'}}>Diameter</td>
    <td>DN15~DN6000mm</td>
  </tr>
  <tr>
    <td >Accuracy</td>
    <td>±1%

</td>
  </tr>
  <tr>
    <td>Repeatability</td>
    <td>±0.2% of Span
</td>
  </tr>
  <tr>
    <td>Medium temperature</td>
    <td>＜60’C
</td>
    
  </tr>
  <tr>
    <td>Flow rate </td>
    <td>0.3～32m/s
</td>

  </tr>
  <tr>
    <td>Pipe Material </td>
    <td>steel, cast iron, Ductile iron,Brass,copper,,PVC, Aluminium </td>
  </tr>
  
  <tr>
    <td>Displays </td>
    <td>4x16 English letters
</td>
  </tr>
  <tr>
    <td style={{width:'25%'}}>Electronic Enclosure Material</td>
    <td>Plastic, Aluminium</td>
  </tr>
  <tr>
    <td>Power supply</td>
    <td>230VAC/110VAC/24VDC/Battery
</td>
  </tr>
  <tr>
    <td>Signal output </td>
    <td>4～20mA, Pulse
</td>
    
  </tr>
  
  <tr>
    <td>Communication </td>
    <td>RS-232C,RS485,M-BUS,RF,GSM/GPRS,LORA/NB-IOT
 </td>
  </tr>
  <tr>
    <td>Data Logger</td>
    <td>Options : SD card data ( up to 8GB based SD card capacity) </td>
  </tr>
  <tr>
    <td>Protection proof</td>
    <td>IP65; IP67,IP68
</td>
  </tr>
  <tr>
    <td>Explosion-proof</td>
    <td>Flame Proof (FLP)
</td>
  </tr>
  <tr>
    <td>Structure </td>
    <td>Integral, Remote
</td>
  </tr>
  <tr>
    <td>Liquid temperature</td>
    <td>-40 to 121 Deg.C. (Optional 40 to 250 Deg.C.)


</td>
  </tr>
  <tr>
    <td>Work environment</td>
    <td>Ambient temperature：-20～+60H，Ambient humidity：5%～90%
</td>
  </tr>
  <tr>
    <td>Sensors for Clamp-on Type</td>
    <td>S1 (15-100 mm), M1(50-700 mm) and L1(300- 4570 mm).</td>
  </tr>
  <tr>
    <td>Sensors for Insertion Type</td>
    <td>Above 80mm</td>
  </tr>
  <tr>
    <td>Sensors for Inline Type</td>
    <td>DN15~DN600mm</td>
  </tr>
  <tr>
    <td>Types of UltaFlow 2500</td>
    <td>Clamp-on, Insertion, Inline.</td>
  </tr>
  
</table>
</Container>
 
       <Products/>
</Fade>


        <Footer/>
    
</>
    
  );
  
}

export default CarouselPage;

