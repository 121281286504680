import React  from 'react';
import Navbar from '../../navbar/header'
import '../plc/plc.css'
import { Row,Col, Container } from 'react-bootstrap';
import VS1 from '../../../assets/VS1.jpg'
import VB1 from '../../../assets/VB1.jpg'
import VB2 from '../../../assets/VB2.jpg'
import VH1 from '../../../assets/VH1.jpg'
import Fade from 'react-reveal/';
import VFDAC10 from '../../../assets/VFDAC10.jpg'
import HMI1 from '../../../assets/HMI1.png'
import SCADAS from '../../../assets/SCADAS.jpg'
import Footer from '../../footer/footer'
import plcc1s1 from '../../../assets/plcc1s1.jpg'
import plcc2s2 from '../../../assets/plcc2s2.jpg'
import plcc3s3 from '../../../assets/plcc3s3.jpg'
import {Link} from 'react-router-dom'
import iots from '../../../assets/iots.jpg'
import invthmivs from '../../../assets/invthmivs.jpg'
import vfdgd20 from  '../../../assets/vfd/gd20-1.jpg'





function vfd() {

    return (
        <>
            <Navbar/>
            <h1 style={{textAlign:'center',marginTop:'2rem',marginLeft:'1rem',color:'red'}}>PROGRAMMING LOGIC CONTROLLER</h1>
            <Container>
            <Row>
    <Col md='4'>
    <div class="card">
  <img
    src={plcc1s1}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">IVC1L Series</h5>
    <Link to="/plcc1" class="btn btn-danger">Click Here</Link>
  </div>
</div>
</Col>
        <Col md='4'>
    <div class="card">
  <img
    src={plcc2s2}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">IVC2 Series</h5>
   
    <Link to="/plcc2" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col>
        <Col md='4'>
    <div class="card">
  <img
    src={plcc3s3}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
    <h5 class="card-title">
    IVC3 Series</h5>
    <Link to="/plcc3" class="btn btn-danger">Click Here</Link>
  </div>
</div>
        </Col >
        
    </Row>
    </Container>
    
    <Fade right>  <h1 style={{textAlign:'center',color:'red',marginTop:'2rem'}}>RELATED PRODUCTS</h1></Fade>
    <Container>
<Row style={{marginTop:'2rem'}}>
      <Col span={6}>
      <div class="card">
  <a  href='#/hmi'><img src={invthmivs} alt="Avatar" style={{width:'100%'}}/></a>
  <div class="container">
    <h4 style={{color:'red'}}><b>HMI</b></h4>
    
  </div>
</div>


      </Col>
      <Col span={6}>

      <div class="card">
  <a href='#/vfd'><img src={vfdgd20} alt="Avatar" style={{width:'100%'}}/></a>
  <div class="container">
    <h4 style={{color:'red'}}><b>VFD</b></h4>
  </div>
</div>
      </Col>
      <Col span={6}>
      <div class="card">
  <a href='#/iot'><img src={iots} alt="Avatar" style={{width:'100%'}}/></a>
  <div class="container">
    <h4 style={{color:'red'}}><b>IOT</b></h4>
  </div>
</div>

      </Col>
      
    </Row>
</Container>
    
<Footer/>            
        </>
    )
}

export default vfd



