import React from 'react'
import { Carousel } from 'react-bootstrap'

import   './content1.css'
import f1 from '../../assets/f1.jpg'
import  f2 from '../../assets/f2.jpg'
import  f3 from '../../assets/f3.jpg'
import  f4 from '../../assets/f4.jpg'
import  f5 from '../../assets/f5.jpg'
import  f6 from '../../assets/f6.jpg'
import  f7 from '../../assets/f7.jpg'
import  f8 from '../../assets/f8.jpg'
import  f9 from '../../assets/f9.jpg'






function contentone() {
    return (
        
        <Carousel >
  <Carousel.Item>
    <img 
    style={{height:'35rem',width:'100%'}}

      src={f1}
      alt="First slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item  >
    <img
      style={{height:'35rem',width:'100%'}}
      src={f2}
      alt="Third slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item >
    <img
      style={{height:'35rem',width:'100%'}}
      src={f3}
      alt="Third slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item  >
    <img
      style={{height:'35rem',width:'100%'}}
      src={f4}
      alt="Third slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item  >
    <img
      style={{height:'35rem',width:'100%'}}
      src={f5}
      alt="Third slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item  >
    <img
      style={{height:'35rem',width:'100%'}}
      src={f6}
      alt="Third slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item  >
    <img
      style={{height:'35rem',width:'100%'}}
      src={f7}
      alt="Third slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item  >
    <img
      style={{height:'35rem',width:'100%'}}
      src={f8}
      alt="Third slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item  >
    <img
      style={{height:'35rem',width:'100%'}}
      src={f9}
      alt="Third slide"
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>

    )
}

export default contentone
