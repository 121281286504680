import React  from 'react';
import Navbar from '../../components/navbar/header'
import Footer from '../../components/footer/footer'
import { Row,Col, Container } from 'react-bootstrap';
import invthmivs from '../../assets/invthmivs.jpg'
import { Tab,Tabs } from 'react-bootstrap';
import specs from '../../assets/hmivsspec.jpg'
import hmivscatalog from '../../assets/brochers/hmivscatalog.pdf'
import hmivstool from '../../assets/brochers/hmivstool.rar'

function hmivs() {
    return (
        <>
        <Navbar/>
        
<Row>
    <Col md={6}>
<img src={invthmivs}/>
    </Col>
        <Col md={6}>
            <Container>
<h1 style={{fontSize:'60px',paddingTop:'30px',paddingRight:'30px',color:'red'}}>VS Series HMI</h1>
<p style={{fontSize:'30px',paddingRight:'60px'}}>VS series HMIs provide powerful performance, rich display, easy configuration, and large storage capacity, implementing human and machine interaction in various industrial automation fields through simplified human-machine interfaces.</p>
<Row>
      <Col md={6}>
      <button onClick={()=>window.open(hmivscatalog)}  type="button" class="btn btn-danger">Catalog</button>
       < button onClick={()=>window.open(hmivstool)} style={{float:'right'}} type="button" class="btn btn-danger">HMI Tool</button>
      </Col>
      </Row>
</Container>
</Col>
</Row>
<Container   class="w3-container w3-teal" >
  <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
  <Tab eventKey="home" title="Features">
    <div>

    <p><br/>Carry high-performance industrial<br/>Cortex-A8 processor, main frequency can be up to 600MHz<br/>Improve data processing efficiency greatly</p><p><br/>Built-in COM port is integrated with RS232/422/485 communication modes<br/>Support industrial Ethernet communication, realize data exchange among multiple devices via built-in 10M/100M self-adaptive network card<br/>Support free port communication protocol</p><p><br/>Abundant built-in vectorgram library<br/>Support customized vectorgram<br/>Provide standard outline for common vectorgram<br/>Support macro instruction programming, C language script<br/>Support U-disk update and data import/export</p><p><br/>PC can communicate with PLC directly via HMI<br/>Support two HMIs to communicate simultaneously with one PLC<br/>Online simulation function, PC can be connected to PLC directly to simulate configuration project<br/>Alarm record memory can be expanded</p><p><br/>Support five languages<br/>Support TRUE TYPE (TTF) font<br/>Support complicated font edit<br/>Unicode coding, international standard</p><p><br/>Industry-level design enables stable operation<br/>High-capacity FLASH, power-off memory, support U-disk storage<br/>New password system which is safer and more reliable</p>             

                          </div> 
    <div/>
  </Tab>
  <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
<img src={specs}/>

    <div />
  </Tab>
</Tabs>
</Container>
<Footer/>
 </>
)
}
export default hmivs