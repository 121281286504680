import React, {  } from 'react';
import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import '../level/level.css'
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask,  } from
"mdbreact";
import Fade from 'react-reveal/Fade';
import { Row,Col,Container} from 'react-bootstrap'
import Products from '../../products/products'
import l1 from '../../../assets/levelimg/l (1).jpg'
import l2 from '../../../assets/levelimg/l (2).jpg'
import l3 from '../../../assets/levelimg/l (3).jpg'
import l4 from '../../../assets/levelimg/l (4).jpg'
import l5 from '../../../assets/levelimg/l (5).jpg'
import l6 from '../../../assets/levelimg/l (6).jpg'
import l7 from '../../../assets/levelimg/l (7).jpg'
import l8 from '../../../assets/levelimg/l (8).jpg'

import ultpdf from '../../../assets/brochers/ult.pdf'









    const CarouselPage = () => {
      
  return (
    <>

           <Navbar />
           
      <Row>
        <Col sm={6}>
        <MDBCarousel
      activeItem={1}
      length={8}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={l1}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={l2}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={l3}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
        
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={l4}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
        
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={l5}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
        
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="6">
          <MDBView>
            <img
              className="d-block w-100"
              src={l6}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
        
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="7">
          <MDBView>
            <img
              className="d-block w-100"
              src={l7}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
        
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="8">
          <MDBView>
            <img
              className="d-block w-100"
              src={l8}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
        
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
    <button onClick={()=>window.open(ultpdf )} class="btnn" style={{width:'70%',marginRight:'3rem',marginTop:'2rem',marginLeft:'7rem'}}><i class="fa fa-download"></i>DOWNLOAD TECHNICAL DATASHEET</button>

        </Col>
        <Col  sm={6}>
          
        <h2 className='headername' style={{color:'red',marginTop:'3rem',marginLeft:'5rem'}}>ULTRASONIC LEVEL TRANSMITTER</h2>
        <Fade bottom>
        <p style={{marginLeft:'5rem',marginBottom:'2rem',marginRight:'5rem',fontSize:'20px'}} >
        Ultrasonic level measuring instrument, taking the advantages of various many
level measuring instruments, is a universal one characterized by total digitalized and
humanized design. It has perfect level monitoring, data transmission and
man-machine communication. It is featured by strong anti-interference performance; free setting of upper and
lower limits and online output regulation, on-site indication, optional analog, switching value, and RS485 output and easy connection with main unit. The cover, made of waterproof engineering plastics, is small and firm with ABS probe. Therefore, it is applicable for various fields concerning level measuring and
monitoring. According to the practical situation, it also can add other modules, such
as RS 485, current output; it can be match with PLC better.
        </p>
        </Fade>
        </Col>
        </Row>
        
        
        
     <Fade bottom>
        
        
   
    <Container>
      <Row>
        <Col md ='6'>
          <h1 style={{color:'red',marginTop:'2rem',textAlign:'left'}}>APPLICATIONS</h1>
          <ol style={{marginTop:'1rem'}} >
<li>Proximity Detection</li>
<li>Robot Range Finder</li>
<li>Sedimentation Process</li>
<li>Coal Washing Process</li>
<li>Obstacle Detection,Collision avoidance</li>
<li>General-Purpose Distance Detection</li>
<li>Wastewater Treatment</li>

</ol>
          </Col>
          <Col md ='6'>
          <h1 style={{color:'red',marginTop:'2rem',textAlign:'left'}}>FEATURES</h1>
          <ol style={{marginTop:'1rem'}}>
          <li>High Precise</li>
          <li>Non-Invasive</li>
          <li>Strong Anti-Interference</li>
          <li>Automatic Temperature Compensation</li>
          <li>Selectable Output Way</li>
          <li>Substantially Improved Long-Term Stability</li>


</ol>
          </Col>
      </Row>
      <Row>
        <Col md ='6'>
          <h1 style={{color:'red',marginTop:'2rem',textAlign:'left'}}>SPECIFICATION</h1>
          <ol style={{marginTop:'1rem'}} >
          <p>1.Full scale: 5m,10m,15m,20m,25m,30m<br></br>
          2.Max measure error: 1%xfull scale<br></br>
          3.Power: DC  12-30V power consumption: lessthen 1.5W<br></br>
          4.Frequency: 20 KHz&sim;43.0 KHz<br></br>
          5.Output signal: 4&sim;20mA(other output is optional)<br></br>
          6.Max load impedance: 300&#8486;<br></br>
          7.Display panel: LED,LCD.</p>


</ol>
          </Col>
          <Col md ='6'>
          <ol style={{marginTop:'5.5rem'}}>
          <p> 8.Material: ABS for shell,PP for transducer<br></br>
          9.Fix model: OUT straight screw M60*2<br></br>
          10.Admission cable: 1.5m<br></br>
          11.Protection degree: IP65<br></br>
          12.Humidity: 0to95%<br></br>
          13.Ambient temperature: -10C&sim;60C<br></br>
          14.Ambient pressure: normal condition<br></br></p>




</ol>
          </Col>
      </Row>
      </Container>
     
            
   <Products/>
    </Fade>
            <Footer/>
        </>
        );
      
    }
    
         
        
    
              export default CarouselPage;

    
  


