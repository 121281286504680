import React from 'react'
import './footer.css'
import { FaAddressBook ,FaEnvelope,FaPhoneSquareAlt,FaYoutubeSquare,FaWhatsappSquare,FaLinkedin,FaFacebookSquare,FaTwitterSquare,FaInstagramSquare} from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import {Link} from 'react-router-dom'


<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
function footer() {
    return (
       <>
       <div id="footer">
 <div class="footer_area">
        <div class="container">
            <div class="footer_row row">
                <div class="col-md-3 col-sm-6 footer_about">
                    <h2>LOCATION</h2>
                        <iframe title='location' class="maps" src = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.563214135347!2d78.27160581470532!3d17.528351887994223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbf30ac67418a9%3A0xbb28cfea3ba3e3b6!2sMIRRANT%20AUTOMATION%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1608045652013!5m2!1sen!2sin'></iframe>

                </div>
                <div class="col-md-3 col-sm-6 footer_about quick">
                    <h2>PRODUCTS</h2>
                    <ul class="quick_link">
                        <li><Link to="/"><i class="fa fa-chevron-right"></i>HOME</Link></li>
                        <li> <Link to="/uswm"><i class="fa fa-chevron-right"></i>ULTRASONIC WATER METER</Link></li>
                        <li><Link to="/ultra"> <i class="fa fa-chevron-right"></i>ULTRASONIC FLOW METER</Link></li>
                        <li><Link to="/electro"> <i class="fa fa-chevron-right"></i>ELECTROMAGNETIC FLOW METER</Link></li>
                        <li><Link to="/vortex"> <i class="fa fa-chevron-right"></i>VORTEX FLOW METER</Link></li>
                        <li><Link to="/ovelgear"> <i class="fa fa-chevron-right"></i>OVAL-GEAR FLOW METER</Link></li>
                        <li><Link to="/turbine"> <i class="fa fa-chevron-right"></i>TURBINE FLOW METER</Link></li>
                        <li><Link to="/btumeter"> <i class="fa fa-chevron-right"></i>BTU METER</Link></li>
                        <li><Link to="/level"> <i class="fa fa-chevron-right"></i>ULTRASONIC LEVEL TRANSMITTER</Link></li>
                        <li><Link to="/radar"> <i class="fa fa-chevron-right"></i>RADAR LEVEL TRANSMITTER</Link></li>
                        <li><Link to="/fuel"> <i class="fa fa-chevron-right"></i>FUEL DISPENSER</Link></li>


                    </ul>
                </div>
                <div class="col-md-3 col-sm-6 footer_about">
                    <h2>AUTOMATION</h2>
                    <ul class="quick_link">
                        <li> <Link to="/plc"><i class="fa fa-chevronright"></i>PLC</Link></li>
                        <li><Link to="/hmi"><i class="fa fa-chevron-right"></i>HMI</Link></li>
                        <li><Link to="/iot"> <i class="fa fa-chevron-right"></i>IOT</Link></li>
                        <li><Link to="/vfd"> <i class="fa fa-chevron-right"></i>VFD</Link></li>
                    </ul>
             
                </div>
                <div class="col-md-3 col-sm-6 footer_about">
                    <h2>CONTACT US</h2>
                        <ul class="my_address" style={{color:'black'}}>
                        
                        <h6 style={{whiteSpace:'break-spaces'}}><ImLocation/>&nbsp; PLOT NO:6A/5,IDA <br></br> PHASE-I,PATANCHERU,
                        SANGAREDDY, DIST,<br></br>HYDERABAD-502319,
TELANGANA,INDIA.</h6>
                        <h6 style={{textAlign:'left'}}><FaEnvelope/> &nbsp;  sales@mirrant.com/<br></br> sudhakar@mirrant.com</h6>
                        <h6><FaPhoneSquareAlt/> &nbsp;  +91 9949306860</h6>  
                        <h6><FaAddressBook/> &nbsp;  +91 9515806860 </h6>                  
                

                        </ul>
                        <i onClick={()=>window.open("https://wa.me/919949306860")} style={{color:'green',fontSize:'30px'}}><FaWhatsappSquare/></i>
                       <i onClick={()=>window.open("https://www.facebook.com/Mirrant-Automation-Private-Limited-574879925993283")} style={{color:'#4267B2',fontSize:'30px'}}><FaFacebookSquare/></i>
                        <i onClick={()=>window.open("https://twitter.com/MirrantL")} style={{color:'#1DA1F2',fontSize:'30px'}}><FaTwitterSquare/></i>
                       <i onClick={()=>window.open("https://www.instagram.com/mirrantautomation/")} style={{color:'#e95950	',fontSize:'30px'}}><FaInstagramSquare/></i>
                      <i onClick={()=>window.open("https://youtu.be/si3ejik_hV4")} style={{color:'#FF0000',fontSize:'30px'}}><FaYoutubeSquare/></i>



                </div>
            </div>
        </div>
        
        <div class="copyright_area">
            Copyright 2021 All rights reserved. <a href="mirrant.com">mirrant.com</a>
        </div>
    </div>
    </div>
       </>
    )
    }

export default footer
