import React  from 'react';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Navbar from '../navbar/header'
import Footer from '../footer/footer'
import  Container  from 'react-bootstrap/Container';
import  Row  from 'react-bootstrap/Row';
import  Col  from 'react-bootstrap/Col';
import gd201 from '../../assets/vfd/vfdgd100pv/vfdgd100pv1.jpg'
import gd202 from '../../assets/vfd/vfdgd100pv/vfdgd100pv2.jpg'
import gd203 from '../../assets/vfd/vfdgd100pv/vfdgd100pv3.jpg'
import gd204 from '../../assets/vfd/vfdgd100pv/vfdgd100pv4.jpg'
import gd205 from '../../assets/vfd/vfdgd100pv/vfdgd100pv5.jpg'

import { Tab,Tabs } from 'react-bootstrap';
import specs1 from '../../assets/vfd/vfdgd100pv/specs1.png'
import specs2 from '../../assets/vfd/vfdgd100pv/specs2.png'

import vfdgd100pvcatalog from '../../assets/brochers/vfdgd100pvcatalog.pdf'
import vfdgd100pvmanual from '../../assets/brochers/vfdgd100pvmanual.pdf'




function vfdgd100pv() {
    

    return (
  
        <>
              <Navbar/>

       <Container className="container-fluid" style={{paddingTop:'0px'}}>
<Row>
  <Col md={6}>
<MDBCarousel
      activeItem={1}
      length={5}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
      width="100%"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd201}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd202}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem>
        
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd203}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem> <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd204}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem> <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd205}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
    </Col>
<Col md="6">
    <h4 style={{paddingTop:'100px'}}>GD100-PV Series Solar Water Pump Inverter
</h4>
    <p>GD100-PV series solar vfd drives are that INVT newly launches specially for solar pumping applications. Based on the original solar pump inverter products, which optimizes the usability and performance, and extends applicable voltage levels and power range of the product. The voltage level can be applied to single phase/three phase 220V, three phase 380V pumps, power range from 0.4kW to 110kW.</p>
    <Row>
      <Col md={6}>
      <button  onClick={()=>window.open(vfdgd100pvcatalog)} type="button" class="btn btn-danger">Catalog</button>
       < button  onClick={()=>window.open(vfdgd100pvmanual)} style={{float:'right'}} type="button" class="btn btn-danger">Manual</button>

      </Col>
      </Row>
    </Col>

</Row>
</Container>
<div style={{paddingTop:'30px'}}>
  </div>
<Container   class="w3-container w3-teal" >
  <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
  <Tab eventKey="home" title="Features">
    <div>
    <p>● Comprehensive voltage level and power range<br/>Support single phase/three phase 220V, and three phase 380V <em>solar water pump inverter</em>, power from 0.4kW to 110KW</p><p>● Easy to use<br/>Simply connect the photovoltaic panel to the inverter, no need to set any parameters, and the PV pump can be automatically started after power-on</p><p>● Multiple protection measures<br/>
 It has protection functions such as PV over-voltage protection, PV polarity reverse connection alarm, over-temperature automatic derating, etc., which can effectively extend the life of the product</p><p>● Advanced MPPT algorithm<br/>Ensure solar power tracking efficiency can reach 99%</p><p>● Boost module<br/>
 2.2KW and below models can be equipped with boost module to meet the needs of low voltage operation, which can reduce solar panel configuration and reduce user system cost</p><p>● GPRS remote monitoring<br/>
 Supports optional GPRS module, which can realize remote monitoring function of computer webpage and mobile APP</p><p>● AC/DC switching scheme<br/>
 All series of products can realize automatic switching of PV DC input and grid AC input, which can meet 24 hours of no care work</p><p>● IP54 solution<br/>
 Supports IP54 grade cabinet solutions</p><p><iframe src="https://www.youtube.com/embed/oirPWWIcHVg" width="640" height="360" frameborder="0"></iframe></p>   </div>
  </Tab>
  <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
  <img src={specs1}/>
  <img src={specs2}/>

    <div />
  </Tab>
 
</Tabs>
</Container>

<Footer/>
</>
    )
}
export default vfdgd100pv