import React  from 'react';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Navbar from '../navbar/header'
import Footer from '../footer/footer'
import  Container  from 'react-bootstrap/Container';
import  Row  from 'react-bootstrap/Row';
import  Col  from 'react-bootstrap/Col';
import gd201 from '../../assets/vfd/vfddd180/vfddd1801.jpg'
import gd202 from '../../assets/vfd/vfddd180/vfddd1802.jpg'
import gd203 from '../../assets/vfd/vfddd180/vfddd1803.jpg'
import gd204 from '../../assets/vfd/vfddd180/vfddd1804.jpg'

import { Tab,Tabs } from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import specs from '../../assets/vfd/vfddd180/specs.jpg'
import vfdgd180catalog from '../../assets/brochers/vfdgd180catalog.pdf'
import vfdgd180manual from '../../assets/brochers/vfdgd180manual.pdf'



function vfdda180() {
    

    return (
  
        <>
               <Navbar/>

     <Container className="container-fluid" style={{paddingTop:'0px'}}>
<Row>
  <Col md={6}>
<MDBCarousel
      activeItem={1}
      length={4}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
      width="100%"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd201}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd202}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd203}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd204}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
       
      </MDBCarouselInner>
    </MDBCarousel>
    </Col>
<Col md="6">
    <h2 style={{paddingTop:'100px'}}>DA180 Economy AC Servo Drives</h2>
    <p>DA180 series basic AC servo drive is the new generation of INVT simplified single-axis servo product. Utility oriented,DA180 focuses on the essential of manufacturing, achieving quick need response and making expansion easy. It provides efficient and competitive solutions for the simplification, networking requirements of general-purpose equipment.</p>
    <Row>
      <Col md={6}>
      <button  onClick={()=>window.open(vfdgd180catalog)} type="button" class="btn btn-danger">Catalog</button>
       < button  onClick={()=>window.open(vfdgd180manual)} style={{float:'right'}} type="button" class="btn btn-danger">Manual</button>

      </Col>
      </Row>
    </Col>

</Row>
</Container>
<div style={{paddingTop:'30px'}}>
  </div>
<Container   class="w3-container w3-teal" >
  <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
  <Tab eventKey="home" title="Features">
  <div class="con_tab"> 
  <p>DA180 drive is a new generation of basic servo drive that INVT develops, using the modular design, USB communication with the upper computer software, and bus control options Modbus, EtherCAT and CANopen. In addition, DA180 drive supports online and offline inertia identifying, gain switching, auto and manual notch filters, internal point-to-point (PTP) control, and multiple types of encoders.</p><p>The electromagnetic compatibility design enables DA180 drive to provide strong anti-electromagnetic interference capacity but also achieve low noise and electromagnetic interference weakening in the application sites.</p>               </div> 

    <div />
  </Tab>
  <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
  <img src={specs}/>
    <div />
  </Tab>
 
</Tabs>
</Container>


<Footer/>
</>
    )
}
export default vfdda180