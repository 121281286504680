import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import '../turbine/turbine.css'
import { MDBCarousel, MDBCarouselCaption,MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask,  } from
"mdbreact";
import Fade from 'react-reveal/Fade';
import { Row,Col,Container} from 'react-bootstrap'
import Products from '../../products/products'
import turbinepdf from '../../../assets/brochers/turbine.pdf'
import tubine1 from '../../../assets/turbine/tubine1.jpg'
import tubine2 from '../../../assets/turbine/tubine2.jpg'
import tubine3 from '../../../assets/turbine/tubine3.jpg'
import tubine4 from '../../../assets/turbine/tubine4.jpg'
import tubine5 from '../../../assets/turbine/tubine5.jpg'
import tubine6 from '../../../assets/turbine/tubine6.jpg'
import turbine7 from '../../../assets/turbine/tubine7.jpg'
import turbine8 from '../../../assets/turbine/tubine8.jpg'
import tubine9 from '../../../assets/turbine/tubine9.jpg'
import tubine10 from '../../../assets/turbine/tubine10.jpg'
import tubine11 from '../../../assets/turbine/tubine11.jpg'
import tubine12 from '../../../assets/turbine/tubine12.jpg'
import tubine13 from '../../../assets/turbine/tubine13.jpg'
import tubine14 from '../../../assets/turbine/tubine14.jpg'






    const CarouselPage = () => {
      
  return (
    <>

           <Navbar />
           
      <Row>
        <Col sm={6}>
        <MDBCarousel
      activeItem={1}
      length={14}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine1}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
                     </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine2}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
            
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine3}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine4}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine5}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="6">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine6}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="7">
          <MDBView>
            <img
              className="d-block w-100"
              src={turbine7}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="8">
          <MDBView>
            <img
              className="d-block w-100"
              src={turbine8}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="9">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine9}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="10">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine10}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="11">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine11}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="12">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine12}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="13">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine13}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="14">
          <MDBView>
            <img
              className="d-block w-100"
              src={tubine14}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
                      </MDBCarouselCaption>
        </MDBCarouselItem>
         
      </MDBCarouselInner>
    </MDBCarousel>
        <button onClick={()=>window.open(turbinepdf)} class="btnn" style={{width:'70%',marginRight:'3rem',marginTop:'2rem',marginLeft:'7rem'}}><i class="fa fa-download"></i>DOWNLOAD TECHNICAL DATASHEET</button>
        </Col>
        <Col  sm={6}>
          
        <h2 className='headername' style={{color:'red',marginTop:'3rem',marginLeft:'5rem'}}>TURBINE FLOW METER</h2>
        <Fade bottom>
        <p style={{marginLeft:'5rem',marginBottom:'2rem',marginRight:'5rem'}} >
        Turbine Flow Meter is a volumetric measuring turbine type. The flowing fluid engages the
rotor causing it to rotate at an angular velocity proportional to the fluid flow rate.
The angular velocity of the rotor results in the generation of an electrical signal (AC sine
wave type) in the pickup. The summation of the pulsing electrical signal is related directly
to total flow. The frequency of the signal relates directly to flow rate. The vaned rotor is the
only moving part of the flow meter.
The Turbine flow meter (axial turbine) was invented by Reinhard Woltman and is an accurate and reliable flow meter for liquids and gases. It consists of a flow tube with end connections and a magnetic multi bladed free spinning rotor (impeller) mounted inside; in line
with the flow. The rotor is supported by a shaft that rests on internally mounted supports.<br></br>
The Supports in Process Automatics Turbine Flow Meters are designed to also act as flow
straighteners, stabilizing the flow and minimizing negative effects of turbulence. The Supports also house the unique open bearings; allowing for the measured media to lubricate
the bushes – prolonging the flow meters life span. The Supports are fastened by locking
rings (circlips) on each end.
        </p>
        </Fade>

        </Col>
        </Row>
        
        
        
     
        <Fade bottom>
        
    <Container>
    <h1 style={{color:'red'}}>SPECIFICATION</h1>
    <table  style={{fontSize:'13px'}}>
      <tr >
        <th>Medium   
        </th>
        <th>
        Water, Seawater, Gasoline, lubrication oils, mineral oils, hydraulic
oils, fuels, liquefied gases, Solvents, Chemicals, Beverages etc.
</th>
      </tr>
      <tr>
        <td>Size</td>
        <td>DN4~DN250mm</td>
      </tr>
      <tr>
        <td>Accuracy& Repeatability</td>
        <td>±0.5% & ±0.1% of Span</td>
      </tr>
      <tr>
        <td>Medium temperature</td>
        <td>-20 to 120 Deg. C
    </td>
      </tr>
      <tr>
        <td>Medium pressure </td>
        <td>up to 60 Bar
</td>
        
      </tr>
      <tr>
        <td>Body Material (Kg/Cm2)</td>
        <td>SS304,SS316,Plastic
    </td>
    
      </tr>
      <tr>
        <td>Rotor Material</td>
        <td>SS304,SS316,Plastic
</td>
      </tr>
      
      <tr>
        <td>Shaft Material </td>
        <td>SS316,SS410,Plastic</td>
      </tr>
      <tr>
        <td>Electronic Enclosure</td>
        <td>Aluminium, MS, Plastic</td>
      </tr>
      <tr>
        <td>Power supply</td>
        <td>24V DC,230 VAC, Battery Power</td>
      </tr>
      <tr>
        <td>Signal output</td>
        <td>4～20mA, Pulse Output</td>
        
      </tr>
      <tr>
        <td>Communication </td>
        <td>RS485,GSM/GPRS,LORA/NB-IOT</td>
    
      </tr>
      <tr>
        <td>Display
</td>
        <td>Display1:Battery Powered, Local Display
Display2:Battery Powered, Local Display and Pulse Output
Display3:External 24VDC Powered, Local Display and Pulse Output
External 24VDC Powered, Local Display and 4-20mA Output
External 24VDC Powered, Local Display,4-20mA Output and RS485
Communication
Display4:External 230VAC Powered, Remote Display, Pulse Output,
4-20mA Output Relay, RS485 Modbus Communication and Data log
</td>
      </tr>
      <tr>
        <td>Explosion-proof</td>
        <td>FLP-Flame Proof
</td>
      </tr>
      <tr>
        <td style={{width:'15%'}}>Protection proof</td>
        <td>IP65,IP67,IP68</td>
      </tr>
      <tr>
        <td>Flow Transmitter</td>
        <td>Integral, Remote
    </td>
      </tr>
      <tr>
        <td>Work environment</td>
        <td>Ambient temperature：-20～+60’C，Ambient humidity：5%～85%

    </td>
      </tr>
      <tr>
        <td>End Connection Type </td>
        <td>Thread-end, Flange-end and Tri-clover  
    </td>
      </tr>
      
      
    </table>
    </Container>
     
            
            
    <Products/>
    </Fade>
            <Footer/>
        </>
        );
      
    }
    
         
        
    
              export default CarouselPage;

    
  


