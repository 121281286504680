import React from 'react'
import { Carousel, Container } from 'react-bootstrap'
import video2 from '../../assets/vfd/video2.mp4'
import iot1 from '../../../src/assets/vfd/iot1.jpg'
import '../iotcarousel/iotcarousel.css'

function contentone() {
    return (
        <>
        <div  className= "container-fluid">
        <video  className='videoTag' autoPlay loop muted>
    <source   src={video2} type='video/mp4' />

</video>
</div>




        
</>

    )
}

export default contentone
