import React from 'react';
import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import '../btumeter/btu.css'
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Fade from 'react-reveal/Fade';
import { Row,Col,Container} from 'react-bootstrap'
import Products from '../../products/products'
import b1   from '../../../assets/btuimg/b (1).jpg'  
import b2   from '../../../assets/btuimg/b (2).jpg'  
import b3   from '../../../assets/btuimg/b (3).jpg'  
import b4   from '../../../assets/btuimg/b (4).jpg'  
import b5   from '../../../assets/btuimg/b (5).jpg'  
import b6   from '../../../assets/btuimg/b (6).jpg'  
import b7   from '../../../assets/btuimg/b (7).jpg'  
import b8   from '../../../assets/btuimg/b (8).jpg'  
import b9   from '../../../assets/btuimg/b (9).jpg'  
import btupdf from '../../../assets/brochers/btu.pdf'



    const CarouselPage = () => {
      
  return (
    <>

           <Navbar />
           
      <Row>
        <Col sm={6}>
        <MDBCarousel
      activeItem={1}
      length={9}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={b1}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={b2}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={b3}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={b4}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={b5}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="6">
          <MDBView>
            <img
              className="d-block w-100"
              src={b6}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="7">
          <MDBView>
            <img
              className="d-block w-100"
              src={b7}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="8">
          <MDBView>
            <img
              className="d-block w-100"
              src={b8}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="9">
          <MDBView>
            <img
              className="d-block w-100"
              src={b9}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
        <button class="btnn" onClick={()=>window.open(btupdf)} style={{width:'70%',marginRight:'3rem',marginTop:'2rem',marginLeft:'7rem'}}><i class="fa fa-download"></i>DOWNLOAD TECHNICAL DATASHEET</button>
        </Col>
        <Col  sm={6}>
          
        <h2 className='headername' style={{color:'red',marginTop:'3rem',marginLeft:'5rem'}}>BTU METER</h2>
        <Fade bottom>
        <p style={{marginLeft:'5rem',marginBottom:'2rem',marginRight:'5rem'}} >
        BTU Measurement System is used to measure individual energy consumption in any liquid
heating/cooling systems. This system is also used to measure the performance of the energy
-saving system or the loss of efficiency which is directly tied to the loss of revenue. BTU Meter is supplied complete with flowmeter and temperature probes.
The system real-time detect the temperature of supply and return pipe. It also monitors the
instantaneous flow rate according to the Heat Exchange of Thermodynamics principle. The
BTU meter automatically integrates energy consumption and transfers it to the computer.
The consumption volume can be checked by a tenant or operator with no worries. It provides
real-time information such as instantaneous temperature, flow rate, energy consumption, and
others.
The BTU Meter provides highly accurate thermal energy measurement in chilled water, hot
water and condenser water systems based on signal inputs from two matched temperature
sensors and any of Magnetic or Ultrasonic flow meters. The basic model provides local indication of energy, flow, and temperature data through an alphanumeric display. An isolated
solid state dry contact is provided for energy total. Optional analogue outputs and network
communications are also available.
BTU meter calculates energy consumption by flow rate and temperature difference, 
        </p>
        </Fade>
        </Col>
        </Row>
        
        
        
     <Fade bottom>
        
        
    <Container>
    <h1 style={{color:'red'}}>SPECIFICATION</h1>
    <table  style={{fontSize:'13px'}}>
      <tr >
        <th>Medium
    </th>
        <th>
        Chilled Water, Hot Water and Condenser water system</th>
      </tr>
      <tr>
        <td>Diameter</td>
        <td>DN15~DN6000mm</td>
      </tr>
      <tr>
        <td>Accuracy</td>
        <td>±0.5%

    </td>
      </tr>
      <tr>
        <td>Repeatability</td>
        <td>±0.2% of Span

    </td>
      </tr>
      <tr>
        <td>Medium temperature</td>
        <td>＜180B
</td>
        
      </tr>
      <tr>
        <td>Medium pressure (Kg/Cm2)</td>
        <td>10,16,25,40
    </td>
    
      </tr>
      <tr>
        <td>Flow rate</td>
        <td>0.3～10m/s
</td>
      </tr>
      
      <tr>
        <td>Display </td>
        <td>LCD Display, Flow Rate,Totalizer,heat flow rate, heat totalizer</td>
      </tr>
      <tr>
        <td>Temperature Inputs  </td>
        <td>Pt 100/Pt1000</td>
      </tr>
      <tr>
        <td>Flow rate Input l</td>
        <td>4-20mA</td>
      </tr>
      <tr>
        <td>Flow sensor Input Type </td>
        <td>Ultrasonic, Electromagnetic, Turbine etc</td>
        
      </tr>
      <tr>
        <td>Mounting</td>
        <td>Portable, Wall Mount</td>
    
      </tr>
      <tr>
        <td>Energy Function </td>
        <td>Heat/Cool
</td>
      </tr>
      <tr>
        <td>Power supply</td>
        <td>230VAC/24VDC/Battery Powered
</td>
      </tr>
      <tr>
        <td>Signal output</td>
        <td>4～20mA, Pulse and Relay</td>
      </tr>
      <tr>
        <td>Communication</td>
        <td>RS485,GSM/GPRS,HART
    </td>
      </tr>
      <tr>
        <td>Electronic Enclosurer</td>
        <td>Aluminium, ABS Plastic, MS

    </td>
      </tr>
      <tr>
        <td>Protection proof</td>
        <td>IP65 (compact type); IP67,IP68(suitable for remote type)
    </td>
      </tr>
      <tr>
        <td>Transmitter </td>
        <td>Integral, Remote
    
    </td>
      </tr>
      <tr>
        <td>Work environment</td>
        <td>Ambient temperature-20～+60’C Ambient humidity：5%～90%
    </td>
      </tr>
      <tr>
        <td>End Connection</td>
        <td>Flange-end, Wafer, Tri-Clover ,Clamp on,Insertion</td>
      </tr>
      
    </table>
    </Container>
     
         
            <Products/>
    
            </Fade>
    
            <Footer/>
        </>
        );
      
    }
    
         
        
    
              export default CarouselPage;

    
  


