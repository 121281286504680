import React, { Component } from 'react';
import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import '../radar/radar.css'
import { MDBCarousel, MDBCarouselCaption, MDBRow,MDBFooter,MDBCol,MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
"mdbreact";
import Fade from 'react-reveal/Fade';
import Products from '../../products/products'
import { FaAddressBook,FaPhoneAlt,FaEnvelope,FaPhoneSquareAlt,FaWhatsappSquare,FaLinkedin,FaFacebookSquare,FaTwitterSquare} from "react-icons/fa";
import { Row,Col,Container} from 'react-bootstrap'
import r1 from '../../../assets/radarimg/r (1).jpg' 
import r2 from '../../../assets/radarimg/r (2).jpg' 
import r3 from '../../../assets/radarimg/r (3).jpg' 
import r4 from '../../../assets/radarimg/r (4).jpg' 
import r5 from '../../../assets/radarimg/r (5).jpg' 
import r6 from '../../../assets/radarimg/r (6).jpg' 
import r7 from '../../../assets/radarimg/r (7).jpg' 
import radar1 from '../../../assets/brochers/radar1.pdf'


    const CarouselPage = () => {
      
  return (
    <>

           <Navbar />
           
      <Row>
        <Col sm={6}>
        <MDBCarousel
      activeItem={1}
      length={7}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={r1}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={r2}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
          
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={r3}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={r4}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={r5}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="6">
          <MDBView>
            <img
              className="d-block w-100"
              src={r6}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="7">
          <MDBView>
            <img
              className="d-block w-100"
              src={r7}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
    <button onClick={()=>window.open(radar1)}  class="btnn" style={{width:'70%',marginRight:'3rem',marginTop:'2rem',marginLeft:'7rem'}}><i class="fa fa-download"></i>DOWNLOAD TECHNICAL DATASHEET</button>

        </Col>
        <Col  sm={6}>
        <Fade bottom>
        <h2 className='headername' style={{color:'red',marginTop:'3rem',marginLeft:'5rem'}}> RADAR LEVEL TRANSMITTER </h2>
        <p style={{marginLeft:'5rem',marginBottom:'2rem',marginRight:'5rem',fontSize:'22px'}} >
        Radar level meter antenna by narrow microwave pulse, the pulse propagation in space at
the speed of light, meet the measured medium surface, the part of the energy to be
reflected back, by the same antenna. Emission pulses and the time interval of the
received pulse and the antenna to the measured medium surface is proportional to the
distance. Due to the electromagnetic wave propagation and very high speed, pulse and
receiving pulse time interval is very small (nanosecond) it is difficult to confirm, 90X
series of 26G radar level meter adopts a special demodulation technology, can
accurately identify the transmitted pulse and pulse interval, thus further calculate the
antenna to the measured medium surface distance.
        </p>
        </Fade>
        </Col>
        </Row>
        
        
     
        
        <Fade bottom>
    <Container>
    <h1 style={{color:'red'}}>SPECIFICATION</h1>
    <table  style={{fontSize:'13px'}}>
      <tr >
        <th>Application: Rivers
    </th>
        <th>
        Lakes, Shoal</th>
      </tr>
      <tr>
        <td>Measuring Range</td>
        <td>0-100 Mtrs</td>
      </tr>
      <tr>
        <td>Process Connection</td>
        <td>Thread 40mm/Flange

    </td>
      </tr>
      <tr>
        <td>Temperature</td>
        <td> -40ć ~ 100ć
    </td>
      </tr>
      <tr>
        <td>Process Pressure</td>
        <td>Normal pressure
</td>
        
      </tr>
      <tr>
        <td>Precision</td>
        <td>± 3mm
    </td>
    
      </tr>
      <tr>
        <td>Frequency Range</td>
        <td>26GHz
</td>
      </tr>
      
      <tr>
        <td>Protection Grade</td>
        <td> IP67 / IP65</td>
      </tr>
      <tr>
        <td>Power Supply</td>
        <td>(6 - 24V) DC / Four-wire
 24V DC / Two wire
</td>
      </tr>
      <tr>
        <td>The Signal output</td>
        <td>RS485 / Modbus ( 6~24V DC)
 4~20mA / Hart Two wire ( 24V DC)</td>
      </tr>
      <tr>
        <td>The Scene Display</td>
        <td>Optional</td>
        
      </tr>
      <tr>
        <td>Outer Covering</td>
        <td>Aluminum / Plastic /Single chamber</td>
    
      </tr>
      
      
    </table>
    </Container>
     
<Products/>           
            
    
    </Fade>
         <Footer/>
        </>
        );
      
    }
    
         
        
    
              export default CarouselPage;

    
  


