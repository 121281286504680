
import React from 'react';
import Navbar from '../../navbar/header'
import Footer from '../../footer/footer'
import { Container } from 'react-bootstrap';
import fuel1 from '../../../assets/fuel1.jpg'
import fuel2 from '../../../assets/fuel2.jpg'
import fuel3 from '../../../assets/fuel3.jpeg'
import { Row,Col } from 'react-bootstrap';



const fuel = () => {
      
    return (


<>
<Navbar/>
        
      <div>
         <h1 style={{color:'red'}}> FUEL DISPENSER</h1>
      </div>


      <Container>
            <Row>
    <Col md='4'>
    <div class="card">
  <img
    src={fuel1}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
  </div>
</div>
</Col>
        <Col md='4'>
    <div class="card">
  <img
    src={fuel2}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
   
  </div>
</div>
        </Col>
        <Col md='4'>
    <div class="card">
  <img
    src={fuel3}
    class="card-img-top"
    alt="..."
  />
  <div class="card-body">
  </div>
</div>
        </Col >
        
    </Row>
    </Container>
    <Container>
    <h1 style={{color:'red',textAlign:'center',paddingTop:'30px'}}>Product Specification </h1>
<p><b style={{color:'red'}}>Mirrant</b> produced mobile fuel dispenser is fully automatic microprocessor electronic with precision metering system for fueling diesel,
gasoline and kerosene, separate and combined pump is both offerable with features like 
convenient maintance, high percision flowrate and excellent electrongnetic compatatibilty.</p>
<div>more technical specification of<em><strong>&nbsp;Mobile fuel vending machine</strong> </em>as bellow :<br /><br />mobile fuel dispenser<br /><br /></div>
<div>Flow Rate&nbsp; &nbsp; :- 0-50/60/120L/MIN</div>
<div>Voltage&nbsp; &nbsp; &nbsp; &nbsp; :- AC 220v/ 24V DC/12V DC</div>
<div>Power&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :-550w</div>
<div>Accuracy&nbsp; &nbsp; &nbsp;:-&plusmn;0.3%</div>
<div>Height&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:-52Cm</div>
<div>Allocation&nbsp; &nbsp; :auto nozzle,&nbsp; 4Meter Hose</div>
<h3><u>Product Features ::</u></h3> Mirrant fuel dispenser is best for fuel, diesel, petrol. biodiesel and kerosine VENDING, its a very small mobile base unit to easy to fix on truck and tanker,  
<h3><u>Range ::</u></h3> Mirrant fuel dispenser available from 25 LPM to 120 LPM RANGE,  
<h3><u>Product Applications ::</u></h3>

<li >Fuel</li>
<li >Oil</li>

</Container>
      <Footer/>
        </>
        );
      
    }
    
         


export default fuel;
