import React from 'react'
import { Layout } from 'antd';
import Navbar from '../components/navbar/header'
import Carousel1 from '../components/carousel/contentone'
import Aboutus from '../components/aboutus/aboutus'
import Products from '../components/products/products.js';
import Applications from '../components/applications/applications'
import Clients from '../components/clients/clients'
import Footermap from '../components/footer/footer'



const { Header, Footer, Content } = Layout;

function pagegrid() {
    return (
        <Layout>
      <Header><Navbar /></Header>
      <Content>
        <Carousel1 />
        <Aboutus />
      </Content>
      <Products />
      <Applications />
      <Clients />
      <Footer><Footermap /></Footer>
    </Layout>
    )
    
}


export default pagegrid
