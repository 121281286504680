import React from 'react'
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "./products.css";
import rp1 from '../../assets/related products/rp1.jpg'
import rp2 from '../../assets/related products/rp2.jpg'
import rp3 from '../../assets/related products/rp3.jpg'
import rp4 from '../../assets/related products/rp4.jpg'
import rp5 from '../../assets/related products/rp5.jpg'
import rp6 from '../../assets/related products/rp6.jpg'
import rp7 from '../../assets/related products/rp7.jpg'
import rp8 from '../../assets/related products/rp8.jpg'
import rp9 from '../../assets/related products/rp9.jpg'
import {Link} from 'react-router-dom'


import Fade from 'react-reveal/Fade';





const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 }
  
];


function products() {
  return (
    <>
           <Fade left><h1 style={{color:'red',marginTop:'2rem'}}>RELATED PRODUCTS</h1></Fade>

       <div className="App"  style={{marginTop:'0rem'}}>

      <Carousel breakPoints={breakPoints}>
        
        <Item><Link to="/electro" ><img src={rp1} alt='' /></Link></Item>
       <Item><Link to="/uswm"><img src={rp2} alt=''/></Link></Item>
       <Item><Link to="/ultra"><img src={rp3} alt=''/></Link></Item>
      <Item><Link to="/turbine"><img src={rp4} alt=''/></Link></Item>
        <Item><Link to="/ovelgear"><img src={rp5} alt=''/></Link></Item>
       <Item><Link to="/radar"><img src={rp6} alt=''/></Link></Item>
       <Item><Link to="/btumeter"><img src={rp7} alt=''/></Link></Item>
        <Item><Link to="/level"><img src={rp8} alt=''/></Link></Item>
       <Item><Link to="/vortex"><img src={rp9} alt=''/></Link></Item>

      
      </Carousel>
    </div>
    </>
  )
}

export default products
