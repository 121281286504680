import React  from 'react';
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";
import Navbar from '../navbar/header'
import Footer from '../footer/footer'
import  Container  from 'react-bootstrap/Container';
import  Row  from 'react-bootstrap/Row';
import  Col  from 'react-bootstrap/Col';
import { Tab,Tabs } from 'react-bootstrap';
import Slide from 'react-reveal/Slide';
import gd201 from '../../assets/vfd/gd20-1.jpg'
import gd202 from '../../assets/vfd/gd20-2.jpg'
import gd203 from '../../assets/vfd/gd20-3.jpg'
import gd204 from '../../assets/vfd/gd20-4.jpg'
import gd205 from '../../assets/vfd/gd20-5.jpg'
import vfdgd20s1 from '../../assets/vfd/vfdgd20/vfdgd20s1.png'
import vfdgd20s2 from '../../assets/vfd/vfdgd20/vfdgd20s2.png'
import vfdgd20s3 from '../../assets/vfd/vfdgd20/vfdgd20s3.png'
import vfdgd20s4 from '../../assets/vfd/vfdgd20/vfdgd20s4.png'
import vfdgd20s5 from '../../assets/vfd/vfdgd20/vfdgd20s5.png'
import vfdgd20s6 from '../../assets/vfd/vfdgd20/vfdgd20s6.png'
import vfdgd20s7 from '../../assets/vfd/vfdgd20/vfdgd20s7.png'
import vfdgd20s8 from '../../assets/vfd/vfdgd20/vfdgd20s8.png'
import vfdgd20s9 from '../../assets/vfd/vfdgd20/vfdgd20s9.png'
import vfdgd20s10 from '../../assets/vfd/vfdgd20/vfdgd20s10.png'
import specs from '../../assets/vfd/vfdgd20/specs.PNG'
import vfdgd20catalog from '../../assets/brochers/vfdgd20catalog.pdf'
import vfdgd20manual from '../../assets/brochers/vfdgd20manual.pdf'
function vfdgd20(){
    return (
        <>
       <Navbar/>
       <Container className="container-fluid" style={{paddingTop:'0px'}}>
<Row>
  <Col md={6}>
<MDBCarousel
      activeItem={1}
      length={5}
      showControls={true}
      showIndicators={true}
      className="z-depth-1"
      width="100%"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd201}
              alt="First slide"
            />
          <MDBMask overlay="black-light" />
          </MDBView>
          <MDBCarouselCaption>
           
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd202}
              alt="Second slide"
            />
          <MDBMask overlay="black-strong" />
          </MDBView>
          <MDBCarouselCaption>
    
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd203}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="4">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd204}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="5">
          <MDBView>
            <img
              className="d-block w-100"
              src={gd205}
              alt="Third slide"
            />
          <MDBMask overlay="black-slight" />
          </MDBView>
          <MDBCarouselCaption>
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
    </Col>
<Col md="6">
    <h3 style={{paddingTop:'40px'}}> GD20 Series Open Loop Vector Control Drives</h3>
    <p>GD20 series inverters feature excellent drive and control performance for using of sensorless vector control technology, and improve usability and reliability for the enriched hardware configurations and software functions, meeting different industrial applications. GD20 series 18.5KW~110KW comes standard with built-in DC reactor, 18.5~37KW built-in standard brake unit, 45~110KW optional built-in brake unit, full range of independent air duct design, which improves product reliability and performance, and enhances product competitiveness.</p>
    <Row>
      <Col md={6}>
      <button onClick={()=>window.open(vfdgd20catalog)} type="button" class="btn btn-danger">Catalog</button>
       < button   onClick={()=>window.open(vfdgd20manual)} style={{float:'right'}} type="button" class="btn btn-danger">Manual</button>
      </Col>
      </Row>
    </Col>
</Row>
</Container>
<div style={{paddingTop:'30px'}}>
  </div>
<Container   class="w3-container w3-teal" >
  <Tabs  defaultActiveKey="home" id="uncontrolled-tab-example" className="lg6">
  <Tab eventKey="home" title="Features">
    <div>
                      <p>● Excellent Control Performance<br/>Adopting PG-free vector control mode, excellent control performance</p>
                      <p>Excellent vector control performance</p>
                      <p><img src={vfdgd20s1}/></p>
                      <p>Excellent motor drive performance</p>
                      <p><img src={vfdgd20s2}/></p>
                      <p>Excellent high-frequency running performance</p>
                      <p><img src={vfdgd20s3}/></p>
                      <p>● Optimized Structure Design<br/>Optimized spare utilization, models (≥18.5kW) is much smaller than existing inverters</p>
                      <p><img src={vfdgd20s4}/></p>
                      <p>Mini design for inverters (≤ 2.2kW), abreast installation of multiple inverters, reducing installation space</p>
                      <p><img src={vfdgd20s5}/></p>
                      <p>Flexible installation ways</p>
                      <p><img src={vfdgd20s6}/></p>
                      <p>● Multi-function and easy to use<br/>DC reactors are built-in inverters ≥18.5kW</p>
                      <p><img src={vfdgd20s7}/></p>
                      <p>The braking unit is built-in and standard for inverters ≤37kW but optional for inverters of 45-110kW</p>
                      <p><img src={vfdgd20s8}/></p>
                      <p>Inverters (380V; ≥4kw) support the DC bus sharing solution</p>
                      <p><img src={vfdgd20s9}/></p>
                      <p>Support of external keypad<br/>GD20 series inverters can be configured with LED keypad which has the data copy function to upload or download the parameters.</p>
                      <p><img src={vfdgd20s10}/></p>
                      <p>● Diverse Application Requirements<br/>Simple PLC, multi-speed control, built-in PID, torque control, multi-point V/F curve, multiple braking methods, instantaneous power failure without stopping, etc.</p>
                      <p>● Applications<br/>Vector control platform products, powerful, excellent performance, can be widely used in small and medium power applications, such as food machinery, plastic machinery, ceramic equipment, petroleum machinery, cable machinery, air compressors, machine tools, woodworking machinery, textile machinery, printing and packaging equipment, chemical equipment, environmental protection equipment, transportation equipment, etc.</p>               </div> 
    <div/>
  </Tab>
  <Tab style={{fontcolor:'red'}} eventKey="profile" title="Specification">
  <img src={specs}/>
    <div />
  </Tab>
</Tabs>
</Container>
<Footer/>
</>
    )
}
export default vfdgd20